import React, { useState } from "react";
import EventBlk from "../../common/EventBlk";
import { nowPlus6Days, eventDateFormat } from "../../../helpers/helpers";
import { searchEvents } from "../../../states/actions/fetchEvents";
import { useDispatch, useSelector } from "react-redux";

const AllEvents = ({ data, cats }) => {
  const dispatch = useDispatch();
  const isSearching = useSelector((state) => state.fetchEvents.isSearching);
  const events = useSelector((state) => state.fetchEvents.events);

  const [dateRange, setDateRange] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const FILTERS = {
      dateRange: e.target.value
    };
    setDateRange(e.target.value);
    console.log(FILTERS);
    dispatch(searchEvents(FILTERS));
  };

  return (
    <>
      <section id="events">
        <div className="contain">
          <div className="sort_blk size_4 mb-4">
            <select
              name="dateRange"
              id="dateRange"
              value={dateRange}
              onChange={(e) => handleSubmit(e)}
              className="input w-auto p-0 border-0"
            >
              <option value="">Upcoming Events</option>
              {nowPlus6Days().map((date, index) => (
                <option value={date}>
                  {index === 0 ? "Today" : `Now - ${eventDateFormat(date)}`}
                </option>
              ))}
            </select>
          </div>
          {isSearching ? (
            <p>fetching...</p>
          ) : events.length === 0 ? (
            <h3>No event found.</h3>
          ) : (
            events.map((event) => {
              return <EventBlk event={event} key={event.id} />;
            })
          )}
          <div
            className="btn_blk justify-content-between mt-5"
            style={{ display: "none" }}
          >
            <button type="button" className="site_btn text prev_btn">
              <i className="chevron-left"></i> Previous Event
            </button>
            <button type="button" className="site_btn text next_btn">
              Next Event <i className="chevron-right"></i>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllEvents;
