import React from "react";
import ContactForm from "./ContactForm";
import Text from "./Text";

const ContactUs = ({ content, handleContactSubmit, isFormProcessing }) => {
  return (
    <>
      <section id="contact">
        <div className="contain">
          <div className="text-center contact_line_heading">
            <div className="line_heading text-center">
              <h2>
                <Text string={content.cu_heading} parse={true} />
              </h2>
              <span className="bot_line"></span>
            </div>
            <p>
              <Text string={content.cu_desc} />
            </p>
          </div>
          <div className="in_col abt_form">
            <ContactForm
              content={content}
              handleContactSubmit={handleContactSubmit}
              isFormProcessing={isFormProcessing}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
