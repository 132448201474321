import React, { useEffect } from "react";
import Text from "../../common/Text";
const Detail = ({ article }) => {
  return (
    <>
      <div className="article_blk ckEditor">
        <h1 className="heading text-center">
          <Text string={article.title} />
        </h1>
        <Text string={article.detail} />
      </div>
    </>
  );
};

export default Detail;
