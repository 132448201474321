import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_EVENT_DETAIL_CONTENT,
  FETCH_EVENT_DETAIL_CONTENT_SUCCESS,
  FETCH_EVENT_DETAIL_CONTENT_FAILED,
  REGISTER_EVENT,
  REGISTER_EVENT_SUCCESS,
  REGISTER_EVENT_FAILED
} from "./actionTypes";

export const fetchEventDetail = (id) => (dispatch) => {
  dispatch({
    type: FETCH_EVENT_DETAIL_CONTENT,
    payload: null
  });
  http
    .post(
      "event-detail",
      helpers.doObjToFormData({
        id: id,
        token: localStorage.getItem("authToken")
      })
    )
    .then(({ data }) => {
      dispatch({
        type: FETCH_EVENT_DETAIL_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_EVENT_DETAIL_CONTENT_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};

export const RegisterEvent = (formData) => (dispatch) => {
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: REGISTER_EVENT,
      payload: null
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("register-event", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          toast.success("Registered Sccessfully.", TOAST_SETTINGS);
          dispatch({
            type: REGISTER_EVENT_SUCCESS,
            payload: data
          });
        } else {
          if (data.validationErrors) {
            toast.error(
              <Text string={data.validationErrors} parse={true} />,
              TOAST_SETTINGS
            );
            dispatch({
              type: REGISTER_EVENT_FAILED,
              payload: null
            });
          } else {
            dispatch({
              type: REGISTER_EVENT_FAILED,
              payload: null
            });
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: REGISTER_EVENT_FAILED,
          payload: error
        });
        const { data } = error.response;
        localStorage.removeItem("authToken");
        window.location.reload();
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};
