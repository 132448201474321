import React, { useEffect } from "react";
import TalentForm from "./Talent-form";

import {
  fetchTalentProfile,
  saveTalentProfileAction,
} from "../../../states/actions/fetchTalentProfile";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { ToastContainer } from "react-toastify";

const TalentProfile = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchTalentProfile.content);
  const isLoading = useSelector((state) => state.fetchTalentProfile.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchTalentProfile.isFormProcessing
  );
  const mem = useSelector((state) => state.fetchTalentProfile.mem);
  const { content, current_status, work_experience, job_fields, work_areas } =
    data;

  useEffect(() => {
    dispatch(fetchTalentProfile());
  }, []);

  const saveTalentProfile = (formData) => {
    dispatch(saveTalentProfileAction(formData));
  };

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <section id="dash">
            <div className="contain-fluid">
              <TalentForm
                content={content}
                current_status={current_status}
                work_experience={work_experience}
                job_fields={job_fields}
                mem={mem}
                saveTalentProfile={saveTalentProfile}
                isFormProcessing={isFormProcessing}
                work_areas={work_areas}
              />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default TalentProfile;
