import http from "../../helpers/http";
import httpBlob from "../../helpers/http-blob";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_JOB_PROFILE,
  FETCH_JOB_PROFILE_SUCCESS,
  FETCH_JOB_PROFILE_FAILED,
  APPLY_INTERNAL_JOB,
  APPLY_INTERNAL_JOB_SUCCESS,
  APPLY_INTERNAL_JOB_FAILED,
  SAVE_JOB_INTERNAL,
  SAVE_JOB_INTERNAL_SUCCESS,
  SAVE_JOB_INTERNAL_FAILED
} from "./actionTypes";

export const fetchJobProfile = (formData) => (dispatch) => {
  formData = { ...formData, token: localStorage.getItem("authToken") };
  dispatch({
    type: FETCH_JOB_PROFILE,
    payload: null
  });
  http
    .post("job-detail-profile", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      dispatch({
        type: FETCH_JOB_PROFILE_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_JOB_PROFILE_FAILED,
        payload: error
      });
    });
};

export const applyJob = (formData) => (dispatch) => {
  formData = { ...formData, token: localStorage.getItem("authToken") };
  let cv = formData.cv;
  delete formData.cv;

  let cover = formData.cover;
  delete formData.cover;

  formData = helpers.doObjToFormData(formData);
  if (typeof cv != "undefined") formData.append("cv", cv[0]);
  if (typeof cover != "undefined") formData.append("cover", cover[0]);

  dispatch({
    type: APPLY_INTERNAL_JOB,
    payload: null
  });
  httpBlob
    .post("user/appy-internal-job", formData)
    .then(({ data }) => {
      if (data.status) {
        toast.success("Job applied successfully.", TOAST_SETTINGS);
        dispatch({
          type: APPLY_INTERNAL_JOB_SUCCESS,
          payload: data
        });
      } else {
        if (data.validationErrors) {
          toast.error(
            <Text string={data.validationErrors} parse={true} />,
            TOAST_SETTINGS
          );
          dispatch({
            type: APPLY_INTERNAL_JOB_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: APPLY_INTERNAL_JOB_FAILED,
        payload: error
      });
    });
};

export const saveJobAction = (formData) => (dispatch) => {
  let jobId = formData.id;
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: SAVE_JOB_INTERNAL,
      payload: null
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("save-job-internal", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          data = { ...data, id: jobId };
          toast.success("Job Saved Successfully.", TOAST_SETTINGS);
          dispatch({
            type: SAVE_JOB_INTERNAL_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SAVE_JOB_INTERNAL_FAILED,
            payload: null
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SAVE_JOB_INTERNAL_FAILED,
          payload: error
        });
        const { data } = error.response;
        localStorage.removeItem("authToken");
        window.location.reload();
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};
