import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SUBSCRIPTION_PAYMENT_CONTENT,
  FETCH_SUBSCRIPTION_PAYMENT_CONTENT_SUCCESS,
  FETCH_SUBSCRIPTION_PAYMENT_CONTENT_FAILED,
  SAVE_SUBSCRIPTION_PAYMENT,
  SAVE_SUBSCRIPTION_PAYMENT_SUCCESS,
  SAVE_SUBSCRIPTION_PAYMENT_FAILED
} from "./actionTypes";

export const fetchSubspayment = (post) => (dispatch) => {
  dispatch({
    type: FETCH_SUBSCRIPTION_PAYMENT_CONTENT,
    payload: null
  });
  let formData = {
    token: localStorage.getItem("authToken"),
    id: post.id,
    subscRef: post.subscRef
  };
  http
    .post("subscription-payment", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      dispatch({
        type: FETCH_SUBSCRIPTION_PAYMENT_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SUBSCRIPTION_PAYMENT_CONTENT_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};

export const makeSubsPayment = (formData) => (dispatch) => {
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: SAVE_SUBSCRIPTION_PAYMENT,
      payload: null
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("user/make-subscription-payment", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          localStorage.removeItem("ref");
          localStorage.removeItem("compaign_name");
          toast.success(
            "You have sccessfully subscribed the plan.",
            TOAST_SETTINGS
          );
          dispatch({
            type: SAVE_SUBSCRIPTION_PAYMENT_SUCCESS,
            payload: data
          });
          setTimeout(() => {
            window.location.replace("/subscription");
          }, 2000);
        } else {
          dispatch({
            type: SAVE_SUBSCRIPTION_PAYMENT_FAILED,
            payload: null
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SAVE_SUBSCRIPTION_PAYMENT_FAILED,
          payload: error
        });
        // const { data } = error.response;
        // localStorage.removeItem("authToken");
        // window.location.reload();
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};
