import React, { useState } from "react";
import EventMiniBlk from "../../common/EventMiniBlk";
import JobsEvents from "./Jobs-Event";

const Account = ({ events, saved_jobs, applied_jobs, applied_job_actions }) => {
  const [jobsType, setJobsTyps] = useState(1);
  // const [jobs, setJobs] = useState(applied_jobs);
  const [popupJob, setPopupJob] = useState(false);
  const PopupJobActive = () => {
    setPopupJob(!popupJob);
  };
  const handleToggleJobs = (param) => {
    setJobsTyps(param);
    // if (param == 1) setJobs(applied_jobs);
    // else setJobs(saved_jobs);
  };
  return (
    <>
      <section id="dash">
        <div className="contain-fluid">
          <div className="top_head mb-3">
            <h4 className="heading_dash">Registered Events</h4>
          </div>
          <div className="flex_row event_row row">
            {events.length ? (
              events.map((event) => {
                return (
                  <div className="col col-md-6" key={event.id}>
                    <EventMiniBlk event={event} />
                  </div>
                );
              })
            ) : (
              <p>The event that you have registered will show here.</p>
            )}
          </div>
          <div className="br"></div>
          <div className="br"></div>
          <div className="top_head jobs_dash_tabs">
            <div className="left_tab">
              <h4
                className={jobsType == 1 ? "heading active" : "heading"}
                onClick={() => handleToggleJobs(1)}
              >
                Applied Jobs
              </h4>
              <h4
                className={jobsType == 2 ? "heading active" : "heading"}
                onClick={() => handleToggleJobs(2)}
              >
                Saved Jobs
              </h4>
            </div>
            <div className="add_job_opt" style={{ display: "none" }}>
              <span className="site_btn" onClick={() => PopupJobActive()}>
                Add New Job
              </span>
            </div>
          </div>

          <JobsEvents
            jobs={jobsType == 1 ? applied_jobs : saved_jobs}
            applied_job_actions={applied_job_actions}
            jobsType={jobsType}
          />
        </div>
      </section>

      <div
        className={popupJob ? "popup text_popup active" : "popup text_popup"}
      >
        <div className="table_dv">
          <div className="table_cell">
            <div className="contain">
              <div className="_inner">
                <button
                  type="button"
                  className="x_btn"
                  onClick={() => PopupJobActive()}
                ></button>
                <div className="heading_text">
                  <h4>Add new job</h4>
                  <p>Please fill these fields!</p>
                </div>
                <form action="">
                  <div className="form_row row">
                    <div className="col-sm-12">
                      <h6>Company Name</h6>
                      <div className="form_blk">
                        <input type="text" className="input" />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <h6>Job Title</h6>
                      <div className="form_blk">
                        <input type="text" className="input" />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <h6>Min Sallery</h6>
                      <div className="form_blk">
                        <input type="text" className="input" />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <h6>Max Sallery</h6>
                      <div className="form_blk">
                        <input type="text" className="input" />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <h6>Job Description</h6>
                      <div className="form_blk">
                        <textarea className="input"></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="br"></div>
                  <div className="btn_blk text-center">
                    <button className="site_btn" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
