import React, { useState } from "react";
import Text from "../../common/Text";
import { Link } from "react-router-dom";
import ImageControl from "../../common/ImageControl";
import { websiteLink } from "../../../helpers/helpers";

function News({ content, articles }) {
  return (
    <>
      <section id="home_news">
        <div className="contain">
          <div className="sec_heading text-center">
            <h5>
              <Text string={content.sec6_heading} />
            </h5>
            <h2>
              <Text string={content.sec6_tagline} />
            </h2>
          </div>
          <div className="flex">
            {articles &&
              articles.map((row) => (
                <div className="col">
                  <div className="job_profile_blk">
                    <div class="fig">
                      <Link to={websiteLink(`/blog-detail/${row.id}`)}>
                        <ImageControl
                          src={row.image}
                          folder="blogs"
                          specificWidth="500p_"
                        />
                      </Link>
                    </div>
                    <div class="txt">
                      <h4>
                        <Link to={websiteLink(`/blog-detail/${row.id}`)}>
                          <div>
                            <Text string={row.title} />
                          </div>
                        </Link>
                      </h4>
                      <div>
                        <Text string={row.description} length={200} />
                      </div>
                      <div class="btn_blk">
                        <Link
                          to={websiteLink(`/blog-detail/${row.id}`)}
                          class="site_btn text learn"
                        >
                          Read More<i class="arrow"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="btn_blk text-center">
            <Link to={websiteLink(`/blogs`)} className="site_btn blank">
              <Text string={content.sec6_button_text} />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default News;
