import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { RegisterEvent } from "../../states/actions/fetchEventDetail";
import { useDispatch, useSelector } from "react-redux";
import FormProcessingSpinner from "./FormProcessingSpinner";

function PopupRegister({ close, id }) {
  const dispatch = useDispatch();
  const isFormProcessing = useSelector(
    (state) => state.fetchEventDetail.isFormProcessing
  );
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm();

  const submit = (data) => {
    data = { ...data, id: id };
    dispatch(RegisterEvent(data));
  };

  return (
    <>
      <section className="popup sm" style={{ display: "block" }}>
        <div className="table_dv">
          <div className="table_cell">
            <div className="contain">
              <div className="_inner">
                <button
                  type="button"
                  className="x_btn"
                  onClick={close}
                ></button>
                <h3>Register</h3>
                <form method="POST" onSubmit={handleSubmit(submit)}>
                  <div className="form_row row">
                    <div className="col-sm-12">
                      <h5>First Name</h5>
                      <div className="form_blk">
                        <input
                          type="text"
                          className="input"
                          placeholder="Enter first name"
                          {...register("fname", {
                            required: "First Name is required."
                          })}
                        />
                        <span className="validation-error">
                          {errors.fname?.message}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <h5>Last Name</h5>
                      <div className="form_blk">
                        <input
                          type="text"
                          className="input"
                          placeholder="enter last name"
                          {...register("lname", {
                            required: "Last Name is required.",
                            maxLength: {
                              value: 20,
                              message:
                                "Last Name should Not be greater than 20 characters."
                            },
                            minLength: {
                              value: 2,
                              message:
                                "Last Name should be greater than atleast 2 characters."
                            }
                          })}
                        />
                        <span className="validation-error">
                          {errors.lname?.message}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <h5>Email Address</h5>
                      <div className="form_blk">
                        <input
                          type="text"
                          className="input"
                          placeholder="Enter email address"
                          {...register("email", {
                            required: "Email is required.",
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Please enter a valid email"
                            }
                          })}
                        />
                        <span className="validation-error">
                          {errors.email?.message}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <h5>Phone</h5>
                      <div className="form_blk">
                        <input
                          type="text"
                          className="input"
                          placeholder="Enter phone"
                          {...register("phone")}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <h5>Comment</h5>
                      <div className="form_blk">
                        <textarea
                          {...register("comment")}
                          className="input"
                          placeholder="type soemthing here"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="btn_blk form_btn form_blk">
                    <button
                      type="submit"
                      className="site_btn block"
                      disabled={isFormProcessing}
                    >
                      <FormProcessingSpinner
                        isFormProcessing={isFormProcessing}
                      />
                      Save Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PopupRegister;
