import React from "react";
import { Link } from "react-router-dom";
import ImageControl from "../common/ImageControl";
import Text from "../common/Text";

import { saveJobStat } from "../../states/actions/fetchDashboard";
import { useSelector, useDispatch } from "react-redux";

import { jobProgressColor } from "../../helpers/helpers";
import { makeSalaryString, doFirstUpperRestLower } from "../../helpers/helpers";

const JobBlk = ({
  admin,
  job,
  saveJob,
  isJobSaving,
  handleJobApplyPopup,
  handleShowSigninPopup,
  showSigninPopup
}) => {
  const authToken = useSelector((state) => state.fetchSignin.authToken);

  const dispatch = useDispatch();
  const isStatUpdating = useSelector(
    (state) => state.fetchDashboard.isStatUpdating
  );

  const handleSaveJob = (id) => {
    saveJob({ id: id });
  };

  const handleJobStatUpdate = (formData) => {
    dispatch(saveJobStat(formData));
  };
  return (
    <>
      {job && (
        <div className={admin ? "job_blk edit" : "job_blk"}>
          <div className="top">
            <div className="ico fill">
              {job.image == "" || job.image == null ? (
                <img src="/images/no-image.svg" alt="no image found" />
              ) : (
                <ImageControl
                  src={job.image}
                  folder="companies"
                  isThumb={true}
                />
              )}
            </div>
            <div className="title">
              <h4
                className="mb-0 job_title"
                onClick={() => handleJobApplyPopup(job)}
                style={{ cursor: "pointer" }}
              >
                {job.title}
                {job.is_promoted == "1" && (
                  <span className="featured_job_new">Promoted</span>
                )}
              </h4>
              <div className="company">
                {job.company_link == null || job.company_link == "" ? (
                  <a href="javascript:void(0);">
                    <Text string={job.company_name} />
                  </a>
                ) : (
                  <a href={job.company_link} target="_blank">
                    <Text string={job.company_name} />
                  </a>
                )}
              </div>
              {admin ? (
                ""
              ) : (
                <ul className="mini_lst">
                  <li>
                    <img
                      src={process.env.PUBLIC_URL + "/images/new/location.svg"}
                      alt=""
                    />
                    <Text string={job.city} />
                  </li>
                  <li>
                    <img
                      src={process.env.PUBLIC_URL + "/images/new/teacher.svg"}
                      alt=""
                    />
                    <Text
                      string={`${
                        job.degree_requirement == "no-minimum"
                          ? "No Mminimum"
                          : job.degree_requirement
                      }`}
                    />
                  </li>
                  <li>
                    <img
                      className="sm_icon_pound"
                      src={process.env.PUBLIC_URL + "/images/pound1.png"}
                      alt=""
                    />
                    {makeSalaryString(
                      job.min_salary,
                      job.max_salary,
                      doFirstUpperRestLower(job.salary_interval)
                    )}
                  </li>
                </ul>
              )}
            </div>
            {admin ? (
              <div className="act_btn">
                <Link to="/dashboard">
                  <img
                    src={process.env.PUBLIC_URL + "/images/icon-edit.svg"}
                    alt=""
                  />
                </Link>
              </div>
            ) : (
              <div className="act_btn">
                {job.saved ? (
                  <div type="button" className="book_mark_active">
                    <img
                      src={process.env.PUBLIC_URL + "/images/icon-bookmark.svg"}
                      alt=""
                    />
                  </div>
                ) : (
                  <a
                    type="button"
                    onClick={
                      authToken
                        ? () => handleSaveJob(job.id)
                        : handleShowSigninPopup
                    }
                    disabled={isJobSaving}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/icon-bookmark.svg"}
                      alt=""
                    />
                  </a>
                )}
              </div>
            )}
          </div>
          {admin ? (
            <div className="btm">
              <div className="form_row row">
                <div className="col-xl col-lg-4 col-md-6">
                  <h5>Online Test</h5>
                  <div className="form_blk">
                    <select
                      disabled={isStatUpdating}
                      defaultValue={job.online_test}
                      name="online_test"
                      className={`input ${jobProgressColor(job.online_test)}`}
                      onChange={(e) =>
                        handleJobStatUpdate({
                          saved_id: job.saved_id,
                          field: "online_test",
                          value: e.target.value
                        })
                      }
                    >
                      <option value="">Select</option>
                      <option value="not_invited">Not Invited</option>
                      <option value="waiting_on_hold">Waiting/On Hold</option>
                      <option value="completed">Completed</option>
                      <option value="passed">Passed</option>
                      <option value="not_applicable">Not Applicable</option>
                      <option value="failed">Failed</option>
                      <option value="withdrawn">Withdrawn</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl col-lg-4 col-md-6">
                  <h5>Interview</h5>
                  <div className="form_blk">
                    <select
                      disabled={isStatUpdating}
                      defaultValue={job.interview}
                      name="interview"
                      id=""
                      className={`input ${jobProgressColor(job.interview)}`}
                      onChange={(e) =>
                        handleJobStatUpdate({
                          saved_id: job.saved_id,
                          field: "interview",
                          value: e.target.value
                        })
                      }
                    >
                      <option value="">Select</option>
                      <option value="waiting_on_hold">Waiting/On Hold</option>
                      <option value="completed">Completed</option>
                      <option value="passed">Passed</option>
                      <option value="not_applicable">Not Applicable</option>
                      <option value="failed">Failed</option>
                      <option value="withdrawn">Withdrawn</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl col-lg-4 col-md-6">
                  <h5>Assessment Centre</h5>
                  <div className="form_blk">
                    <select
                      disabled={isStatUpdating}
                      defaultValue={job.second_round_interview}
                      name="second_round_interview"
                      id=""
                      className={`input ${jobProgressColor(
                        job.second_round_interview
                      )}`}
                      onChange={(e) =>
                        handleJobStatUpdate({
                          saved_id: job.saved_id,
                          field: "second_round_interview",
                          value: e.target.value
                        })
                      }
                    >
                      <option value="">Select</option>
                      <option value="waiting_on_hold">Waiting/On Hold</option>
                      <option value="completed">Completed</option>
                      <option value="passed">Passed</option>
                      <option value="not_applicable">Not Applicable</option>
                      <option value="failed">Failed</option>
                      <option value="withdrawn">Withdrawn</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl col-lg-4 col-md-6">
                  <h5>final round interview</h5>
                  <div className="form_blk">
                    <select
                      disabled={isStatUpdating}
                      defaultValue={job.final_round_interview}
                      name="final_round_interview"
                      id=""
                      className={`input ${jobProgressColor(
                        job.final_round_interview
                      )}`}
                      onChange={(e) =>
                        handleJobStatUpdate({
                          saved_id: job.saved_id,
                          field: "final_round_interview",
                          value: e.target.value
                        })
                      }
                    >
                      <option value="">Select</option>
                      <option value="waiting_on_hold">Waiting/On Hold</option>
                      <option value="completed">Completed</option>
                      <option value="passed">Passed</option>
                      <option value="not_applicable">Not Applicable</option>
                      <option value="failed">Failed</option>
                      <option value="withdrawn">Withdrawn</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl col-lg-4 col-md-6">
                  <h5>offer</h5>
                  <div className="form_blk">
                    <select
                      disabled={isStatUpdating}
                      defaultValue={job.offer}
                      name="offer"
                      id=""
                      className={`input ${jobProgressColor(job.offer)}`}
                      onChange={(e) =>
                        handleJobStatUpdate({
                          saved_id: job.saved_id,
                          field: "offer",
                          value: e.target.value
                        })
                      }
                    >
                      <option value="">Select</option>
                      <option value="waiting_on_hold">Waiting/On Hold</option>
                      <option value="received">Received</option>
                      <option value="accepted">Accepted</option>
                      <option value="withdrawn">Withdrawn</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default JobBlk;
