import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";

function SubscriptionPopup({ handleToggleSubscriptionPopup }) {
  return (
    <>
      <ToastContainer />
      <section className="popup sm" style={{ display: "block" }}>
        <div className="table_dv">
          <div className="table_cell">
            <div className="contain">
              <div className="_inner">
                <button
                  type="button"
                  className="x_btn"
                  onClick={handleToggleSubscriptionPopup}
                ></button>
                <h3>Subscription Requied.</h3>
                <div className="form_row row">
                  <div className="col-sm-12">
                    <p>Please subscribe a plan to perform these actions.</p>
                  </div>
                  <div className="btn_blk form_btn form_blk">
                    <Link to="/subscription" className="site_btn block">
                      Subscription Plans
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SubscriptionPopup;
