import React from "react";
import { eventDateFormat, eventTimeFormat } from "../../../helpers/helpers";

const PaymentHistory = ({ payment_history }) => {
  return (
    <>
      <div className="top_head mb-3">
        <h4 className="heading_dash">Payment history</h4>
      </div>
      <div class="payment_history_table">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Description</th>
              <th>Amount</th>
              <th>Trasaction date</th>
              <th>Trasaction time</th>
            </tr>
          </thead>
          <tbody>
            {payment_history && payment_history.length > 0 ? (
              payment_history.map((row, index) => (
                <tr>
                  <td>#00{index + 1}</td>
                  <td>{row.description}</td>
                  <td>£{row.amount}</td>
                  <td>{eventDateFormat(row.transaction_date)}</td>
                  <td>{eventTimeFormat(row.transaction_time)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td></td>
                <td>Payments you have made will show here.</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PaymentHistory;
