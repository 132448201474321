import React from "react";
import { Link } from "react-router-dom";
import * as helpers from "../../helpers/helpers";
import Text from "./Text";
import ImageControl from "../../components/common/ImageControl";
import { websiteLink } from "../../helpers/helpers";

const EventBlk = ({ event }) => {
  return (
    <>
      <div className="event_blk">
        <div className="date">
          <small>{helpers.onlyDayThreeletters(event.event_date)}</small>
          {helpers.onlyDateTwoletters(event.event_date)}
        </div>
        <div className="txt ev_ent">
          <div className="time small">
            {helpers.eventDateFormat(event.event_date)} @
            {helpers.eventTimeFormatNew(event.time_from)}
            {", "}
            {event.time_zone}
          </div>
          <h4 className="title mb-0">
            <Link to={websiteLink(`/event-detail/${event.id}`)}>
              <Text string={event.title} />
            </Link>
          </h4>
          <div className="type small">
            <img
              src={process.env.PUBLIC_URL + "/images/icon-video2.svg"}
              alt=""
            />
            {event.cat_name} Event
          </div>
          <p>
            <Text string={event.short_description} parse={false} length={300} />
          </p>
          <div className="btn_blk">
            <Link
              to={websiteLink(`/event-detail/${event.id}`)}
              className="site_btn text"
            >
              Register Now
              <i className="arrow"></i>
            </Link>
          </div>
        </div>
        <div className="fig">
          <figure>
            <Link to={websiteLink(`/event-detail/${event.id}`)}>
              <ImageControl
                src={event.image}
                folder="events"
                specificWidth="500p_"
              />
            </Link>
          </figure>
        </div>
      </div>
    </>
  );
};

export default EventBlk;
