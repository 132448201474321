import {
  FETCH_ARTICLE,
  FETCH_ARTICLE_SUCCESS,
  FETCH_ARTICLE_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_ARTICLE:
      return {
        ...state,
        isLoading: true,
        content: {},
        mem: {}
      };
    case FETCH_ARTICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
        mem: payload.mem
      };
    case FETCH_ARTICLE_FAILED:
      return {
        ...state,
        isLoading: false,
        content: {},
        mem: {},
        error: payload
      };
    default:
      return state;
  }
}
