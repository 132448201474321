import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Outlet } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
// import { fetchSiteSettings } from "./states/reducers/fetchSiteSettings";
import { fetchSiteSettings } from "./states/actions/fetchSiteSettings";
// Common Pages
import ScrollToTop from "./components/common/ScrollToTop";
import Header from "./components/includes/Header";
import Footer from "./components/includes/Footer";
import Home from "./components/pages/home/Index";
import About from "./components/pages/about/Index";
import Work from "./components/pages/work/Index";
import Partner from "./components/pages/partner/Index";
import Careers from "./components/pages/careers/Index";
import OpenJobs from "./components/pages/open_jobs/Index";
import Faq from "./components/pages/faq/Index";
import Events from "./components/pages/events/Index";
import EventDetail from "./components/pages/event_detail/Index";
import ArticleDetail from "./components/pages/article/Index";
import Signin from "./components/pages/signin/Index";
import Signup from "./components/pages/signup/Index";
import Forgot from "./components/pages/forgot/Index";
import VideoInterview from "./components/pages/video_interview/Index";
import ResetPassword from "./components/pages/reset/Index";

import JobProfile from "./components/pages/job_profile/Index";
import JobProfileDetail from "./components/pages/job_profile/Detail";
import Booking from "./components/pages/booking/Index";
import Privacy from "./components/pages/privacy/Index";
import Disclaim from "./components/pages/disclaimer/Index";
import Terms from "./components/pages/terms/Index";

// Account & Popup
import Dashboard from "./components/pages/dashboard/Index";
import Statistics from "./components/pages/statistics/Index";
import Profile from "./components/pages/profile/Index";
import Subscription from "./components/pages/subscription/Index";

import Blogs from "./components/pages/blogs";
import BlogDetail from "./components/pages/blogs/Detail";
import UkCorporate from "./components/pages/uk_corporate/Index";
import Testimonials from "./components/pages/testimonials/Index";
import RecruitmentProcess from "./components/pages/recruitment_process/Index";
import CvCoverLetter from "./components/pages/cv_cover_letter/Index";
import CvGuidance from "./components/pages/cv_guidance/Index";
import CoverLetterGuidance from "./components/pages/cover_letter_guidance/Index";
import CvBuilder from "./components/pages/cv_builder/Index";
import CoverLetterBuilder from "./components/pages/cover_letter_builder/Index";
import AssessmentCenter from "./components/pages/assessment_center/Index";
import Interview from "./components/pages/interview/Index";
import OnlineTest from "./components/pages/online_test/Index";
import OnlineTestDetail from "./components/pages/online_test/Detail";
import TestStart from "./components/pages/online_test/Apply";
import CareerOptions from "./components/pages/career_options/Index";
import UniVsEmp from "./components/pages/uni_vs_emp/Index";
import VideoInterviewMain from "./components/pages/video_interview/Main";
import InterviewCategoryInstruction from "./components/pages/video_interview/Instruction";
// Error & Popup
import Error from "./components/pages/error/Index";
import PopupVideo from "./components/common/PopupVideo";
import SubscriptionNew from "./components/pages/Subscriptionnew/Index";
import DashSubscription from "./components/pages/dash_subscription/Index";
import SubscriptionOffers from "./components/pages/dash-subscription-offers/Index";
import AmbassadorProgramme from "./components/pages/ambassador/Index";
import TalentProfile from "./components/pages/talent-profile/Index";
import Article from "./components/pages/article-dashboard/Index";
// import PopupRegister from "./components/common/PopupRegister";
import ProfileJob from "./components/pages/profile_job/Index";
import { Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import Apply from "./components/pages/profile_job/Apply";

function App() {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.fetchSignin.authToken);
  useEffect(() => {
    dispatch(fetchSiteSettings());
  }, [authToken]);

  const PageLayout = () => (
    <>
      <Header logged={false} />
      <Outlet />
      <Footer />
    </>
  );

  const PageLogLayout = () => (
    <>
      <Header logged={true} />
      <Outlet />
    </>
  );

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route element={<PageLayout />}>
            #PUBLIC PAGES
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/faq" element={<Faq />} />
            <Route exact path="/events" element={<Events />} />
            <Route exact path="/blogs" element={<Blogs />} />
            <Route exact path="/blog-detail/:id" element={<BlogDetail />} />
            <Route exact path="/subscription" element={<Subscription />} />
            <Route exact path="/privacy-policy" element={<Privacy />} />
            <Route exact path="/disclaimer" element={<Disclaim />} />
            <Route exact path="/terms-conditions" element={<Terms />} />
            #AUTH PAGES
            <Route element={<AuthenticatedRoutes />}>
              <Route exact path="/signin" element={<Signin />} />
              <Route exact path="/signup" element={<Signup />} />
              <Route exact path="/forgot-password" element={<Forgot />} />
              <Route
                exact
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
            </Route>
            #PRIVATE PAGES
            <Route element={<PrivateRoute />}>
              <Route exact path="/event-detail/:id" element={<EventDetail />} />
              <Route path="/open-jobs/:page" element={<OpenJobs />} />
              <Route
                exact
                path="/subscription-payment/:id"
                element={<SubscriptionNew />}
              />
              <Route exact path="/job-profile/:id" element={<Apply />} />
            </Route>
          </Route>
          #PRIVATE DASHBOARD ROUTES
          <Route element={<PageLogLayout />}>
            <Route element={<PrivateRoute />}>
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route
                exact
                path="/dashboard-subscription"
                element={<DashSubscription />}
              />
              <Route
                exact
                path="/subscription-offers"
                element={<SubscriptionOffers />}
              />

              <Route exact path="/profile-settings" element={<Profile />} />
              <Route
                exact
                path="/ambassador-programme"
                element={<AmbassadorProgramme />}
              />
              <Route exact path="/talent-profile" element={<TalentProfile />} />
              <Route exact path="/article/:id/:slug" element={<Article />} />
              <Route exact path="/statistics" element={<Statistics />} />
            </Route>
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
