import React, { useEffect } from "react";
import SubscriptionForm from "./Subscription-form";
import { useParams, useSearchParams } from "react-router-dom";

import { fetchSubspayment } from "../../../states/actions/fetchSubspayment";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import useReferralLink from "../../../hooks/useReferralLink";
import LoadingScreen from "../../common/LoadingScreen";
import { ToastContainer } from "react-toastify";

const SubscriptionNew = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const referralLink = useReferralLink(
    searchParams.get("ref"),
    searchParams.get("compaign_name")
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSubspayment.content);
  const isLoading = useSelector((state) => state.fetchSubspayment.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content, plan } = data;

  useEffect(() => {
    dispatch(fetchSubspayment({ id: id, subscRef: referralLink }));
  }, []);

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <SubscriptionForm
            content={content}
            plan={plan}
            plan_id={id}
            subscRef={referralLink}
          />
        </>
      )}
    </>
  );
};

export default SubscriptionNew;
