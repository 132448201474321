import React, { useEffect } from "react";

import { fetchBlogDetail } from "../../../states/actions/fetchBlogDetail";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { ToastContainer } from "react-toastify";
import Text from "../../common/Text";
import { Link, useParams } from "react-router-dom";
import ImageControl from "../../common/ImageControl";
import { websiteLink } from "../../../helpers/helpers";

const Detail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchBlogDetail.content);
  const isLoading = useSelector((state) => state.fetchBlogDetail.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );

  const { blog, blog_cat, meta_desc, site_settings } = data;
  useEffect(() => {
    dispatch(fetchBlogDetail({ id }));
  }, []);

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <section className="blog_detail">
            <div className="contain">
              <div className="back_btn">
                <Link to={websiteLink("/blogs")}>
                  <img src="../images/new/arrow-down.svg" alt="" />{" "}
                  <span>Return to Resources</span>
                </Link>
              </div>
              <div className="content">
                <div className="cat_current_blog">{blog_cat}</div>
                <h1 className="heading">
                  <Text string={blog.title} />
                </h1>
              </div>
              <div className="fig">
                <ImageControl
                  folder="blogs"
                  src={blog.image}
                  specificWidth="1000p_"
                />
              </div>
              <div className="txt">
                <Text string={blog.description} parse={true} />
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default Detail;
