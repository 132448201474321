import React, { useEffect } from "react";
import Data from "../../dummy";
import AllEvents from "./AllEvents";

import { fetchEvents } from "../../../states/actions/fetchEvents";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { useSearchParams } from "react-router-dom";
import useReferralLink from "../../../hooks/useReferralLink";

const Events = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const referralLink = useReferralLink(
    searchParams.get("ref"),
    searchParams.get("compaign_name")
  );
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchEvents.content);
  const isLoading = useSelector((state) => state.fetchEvents.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );

  const { cats, meta_desc, site_settings } = data;

  useEffect(() => {
    dispatch(fetchEvents({ ref: referralLink }));
  }, []);

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <AllEvents data={Data.events} cats={cats} />
        </>
      )}
    </>
  );
};

export default Events;
