import React, { useEffect } from "react";
import Data from "../../dummy";
import Detail from "./Detail";
import { useParams } from "react-router-dom";

import { fetchEventDetail } from "../../../states/actions/fetchEventDetail";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";

const EventDetail = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchEventDetail.content);
  const isLoading = useSelector((state) => state.fetchEventDetail.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { event, meta_desc, site_settings } = data;

  useEffect(() => {
    dispatch(fetchEventDetail(id));
  }, []);

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Helmet>
            <title>{data.page_title}</title>
            <meta name="title" content={site_settings.site_name} />
            <meta name="description" content={meta_desc.meta_description} />
            <meta name="keywords" content={meta_desc.meta_keywords} />
            <meta property="og:title" content={event.title} />
            <meta
              property="og:descripiton"
              content={meta_desc.og_description}
            />
            <meta property="og:url" content={window.location.href} />
            <meta
              property="og:image"
              content={`${site_settings.site_domain}api/uploads/events/300p_${event.image}`}
            />
            <meta property="og:type" content="article" />
          </Helmet>{" "}
          <ToastContainer />
          <Detail
            data={Data.event_detail}
            show={props.popup}
            event={event}
            id={id}
          />
        </>
      )}
    </>
  );
};

export default EventDetail;
