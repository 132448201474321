import React, { useEffect } from "react";
import Offers from "./Offers";
import Faqs from "./Faqs";

import { fetchSubscriptionOffers } from "../../../states/actions/fetchSubscriptionOffers";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";

const SubscriptionOffers = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSubscriptionOffers.content);
  const isLoading = useSelector(
    (state) => state.fetchSubscriptionOffers.isLoading
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const { content, faqs } = data;

  useEffect(() => {
    dispatch(fetchSubscriptionOffers());
  }, []);

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <section id="dash">
            <div className="contain-fluid">
              <Offers content={content} />
              <div className="gap_mid"></div>
              <Faqs content={content} faqs={faqs} />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default SubscriptionOffers;
