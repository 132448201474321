import React from "react";
import Text from "../../common/Text";

function Welcome({content}) {
  return (
    <>
      <section className="wel_come">
        <div className="contain">
          <div className="content text-center w_sm">
            <div className="line_heading text-center">
                <h2><Text string={content.welcome_heading} parse={true} /></h2>
                <span className="bot_line"></span>
            </div>
            <Text string={content.welcome_text} parse={true} />
          </div>
        </div>
      </section>
    </>
  );
}

export default Welcome;
