import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import JobListing from "./JobListing";
import { useSelector } from "react-redux";
import SubscriptionPopup from "../../common/SubscribePopup";
import { useNavigate } from "react-router-dom";

function PaginatedJobs({
  itemsPerPage,
  jobs,
  isSearching,
  saveJob,
  isJobSaving,
  isJobReporting,
  handleReportAnJob,
  handleShowSigninPopup,
  showSigninPopup,
  showReportPopup,
  handleToggleReportPopup,
  setPageNo,
  pageNo,
  totalJobs,
}) {
  console.log(pageNo, "thios heere");
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.fetchSignin.authToken);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [showSubscripitionPopup, setShowSubscriptionPopup] = useState(false);

  const plan = useSelector((state) => state.fetchSiteSettings.plan);
  const handleToggleSubscriptionPopup = () => {
    setShowSubscriptionPopup(!showSubscripitionPopup);
  };
  const initialPage = pageNo - 1;

  useEffect(() => {
    // const endOffset = itemOffset + itemsPerPage;
    // setCurrentItems(jobs.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(totalJobs / itemsPerPage));
  }, [totalJobs]);

  const handlePageClick = (event) => {
    // console.log(event.selected);
    window.scrollTo({ top: 300, behavior: "smooth" });
    let pageNumber = event.selected + 1;
    if (pageNumber > 2) {
      if (!plan) {
        navigate("/subscription");
        // window.location.replace("/subscription");
        return false;
      } else {
        setPageNo(event.selected + 1);
        navigate(`/open-jobs/${event.selected + 1}`);
        // const newOffset = (event.selected * itemsPerPage) % jobs.length;
        // setItemOffset(newOffset);
      }
    } else {
      setPageNo(event.selected + 1);
      navigate(`/open-jobs/${event.selected + 1}`);
      // const newOffset = (event.selected * itemsPerPage) % jobs.length;
      // setItemOffset(newOffset);
    }
  };

  return (
    <>
      {console.log(initialPage, "test heere pagniation")}
      <JobListing
        jobs={jobs}
        isSearching={isSearching}
        saveJob={saveJob}
        isJobSaving={isJobSaving}
        isJobReporting={isJobReporting}
        handleReportAnJob={handleReportAnJob}
        handleShowSigninPopup={handleShowSigninPopup}
        showSigninPopup={showSigninPopup}
        showReportPopup={showReportPopup}
        handleToggleReportPopup={handleToggleReportPopup}
      />
      <ReactPaginate
        forcePage={initialPage}
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={false}
      />
      {showSubscripitionPopup && (
        <SubscriptionPopup
          handleToggleSubscriptionPopup={handleToggleSubscriptionPopup}
        />
      )}
    </>
  );
}

export default PaginatedJobs;
