import React, { useState } from "react";
import Text from "../../common/Text";

const Faqs = ({ content, faqs }) => {
  const [selected, setSelected] = useState(faqs?.[0]?.id);

  return (
    <>
      <div className="sec_heading text-center">
        <h2>
          <Text string={content.sec2_heading} />
        </h2>
      </div>
      <div className="faq_lst dash_faqs">
        {faqs &&
          faqs.map((f) => (
            <div className={selected === f.id ? "faq_blk active" : "faq_blk"}>
              <h5 className="fancy" onClick={() => setSelected(f.id)}>
                <div>
                  <Text string={f.title} />
                </div>
              </h5>
              {selected === f.id && (
                <div className="txt">
                  <Text string={f.detail} />
                </div>
              )}
            </div>
          ))}
      </div>
    </>
  );
};

export default Faqs;
