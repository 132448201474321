import React from "react";
// import PricePlanBlk from "../../common/PricePlanBlk";
import Text from "../../common/Text";
import { Link } from "react-router-dom";
import { websiteLink } from "../../../helpers/helpers";

const SubscriptionPlan = ({
  data,
  content,
  plans,
  offers,
  subscribed_id,
  subscRef
}) => {
  return (
    <>
      <section id="subscription">
        <div className="contain">
          <div className="text-center sub_scription_heading">
            <h2>
              <Text string={content.banner_heading} />
            </h2>
            <p>
              <Text string={content.banner_tagline} />
            </p>
          </div>
          <div className="flex plan_flex">
            <div className="col">
              <ul className="title_sub_lst">
                <li>
                  <h4>
                    <Text string={content.plans_heading} />
                  </h4>
                  <p>
                    <Text string={content.plan_tagline} />
                  </p>
                </li>
                {offers && offers.map((offer) => <li>{offer.title}</li>)}
                <li>
                  <div className="mini_lbl">
                    <span>
                      <Text string={content.cancel_heading} />
                    </span>
                    <Text string={content.cancel_tagline} />
                  </div>
                </li>
              </ul>
            </div>
            {plans &&
              plans.map((plan) => (
                <div className="col">
                  <ul className="title_sub_lst">
                    <li>
                      <h6>{plan.plan_name}</h6>
                      <h4 className="color">
                        £{plan.price_per_month} <small>/month</small>
                      </h4>
                      <p>{plan.tagline}</p>
                    </li>
                    {offers &&
                      offers.map((offer) =>
                        plan.selected_offers.includes(offer.id) ? (
                          <li>
                            <div className="green_check_img">
                              <span>{offer.title}</span>
                              <img src="images/new/tick-circle.svg" alt="" />
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="gray_check_img">
                              <span>{offer.title}</span>
                              <img src="images/new/close-circle.svg" alt="" />
                            </div>
                          </li>
                        )
                      )}
                    <li>
                      <div className="btn_blk">
                        {subscribed_id > 0 ? (
                          <Link
                            to={subscribed_id == plan.id ? `#` : "#"}
                            className={
                              subscribed_id == plan.id
                                ? "site_btn blank"
                                : "site_btn"
                            }
                          >
                            {subscribed_id == plan.id
                              ? "Current Plan"
                              : "Select Plan"}
                          </Link>
                        ) : (
                          <Link
                            to={websiteLink(
                              `/subscription-payment/${plan.encoded_id}`
                            )}
                            className="site_btn"
                          >
                            Select Plan
                          </Link>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default SubscriptionPlan;
