import React, { useEffect, useState, useMemo } from "react";
import { getBackgroundImageUrl } from "../../../helpers/helpers";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { makeSubsPayment } from "../../../states/actions/fetchSubspayment";
import FormProcessingSpinner from "../../common/FormProcessingSpinner";
import { useForm } from "react-hook-form";
import * as paths from "../../../constants/paths";
import axios from "axios";
import http from "../../../helpers/http";

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          display: "block",
          width: "100%",
          height: "5.3rem",
          fontFamily: "'Red Hat Display', sans-serif",
          fontWeight: "500",
          color: "#061237",
          background: "#fff",
          "text-align": "left",
          padding: "0.6rem 1.4rem",
          "::placeholder": {
            color: "#130a2952",
            fontSize: "15px"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    []
  );

  return options;
};

const SubscriptionForm = ({ content, plan, plan_id, subscRef }) => {
  const options = useOptions();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const isFormProcessing = useSelector(
    (state) => state.fetchSubspayment.isFormProcessing
  );
  const [cardError, setCardError] = useState(null);
  const [cardLoading, setCardLoading] = useState(false);
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm();

  const handleFormSubmit = (data) => {
    setCardLoading(true);
    handleSubmitCard(data);
  };

  const handleSubmitCard = async (post) => {
    setCardError(null);

    if (elements == null) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    try {
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement
        // billing_details: billingDetails
      });
      if (paymentMethodReq.error) {
        setCardError(paymentMethodReq.error.message);
        setCardLoading(false);
        return false;
      } else {
        var form_data = new FormData();
        for (var key in post) {
          form_data.append(key, post[key]);
        }
        form_data.append("payment_method", paymentMethodReq.paymentMethod.id);
        form_data.append("plan_id", plan_id);
        form_data.append("token", localStorage.getItem("authToken"));
        http
          .post(`${paths.API_BASE_URL}user/create-payment-intent`, form_data)
          .then((data) => {
            if (data?.data?.status === 1) {
              const {
                subscriptionId,
                clientSecret,
                clientSecretSetup,
                customerId
              } = data.data;
              let card_result = stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                  card: cardElement
                  // billing_details: billingDetails,
                }
              });

              if (card_result.error) {
                setCardError(card_result.error.message);
                setCardLoading(false);
                return false;
              } else {
                card_result.then((response) => {
                  if (response.error) {
                    setCardError(response.error.message);
                    setCardLoading(false);
                    return false;
                  } else {
                    chargePayment(
                      post,
                      subscriptionId,
                      customerId,
                      response.paymentIntent
                    );
                  }
                });
              }
            } else {
              setCardError(data.data.msg);
              setCardLoading(false);
              return false;
            }
          });
      }
    } catch (err) {
      setCardError(err.message);
      setCardLoading(false);
    }

    // const result = await stripe
    //   // .createToken(elements.getElement("card"))
    //   .createToken(elements.getElement(CardNumberElement))
    //   .then(({ error, token }) => {
    //     setCardLoading(false);
    //     if (error) {
    //       setCardError(error.message);
    //     } else {
    //       const formData = {
    //         plan_id: plan_id,
    //         nonce: token.id,
    //         payment_type: "credit-card",
    //         name_on_card: data.name_on_card,
    //         // lname: data.lname,
    //         email: data.email,
    //         // phone: data.phone,
    //         zip_code: data.zip_code
    //       };
    //       // console.log(formData);
    //       dispatch(makeSubsPayment(formData));
    //     }
    //   })
    //   .catch((error) => {
    //     alert("Something went wrong. Please contact to admin.");
    //     return false;
    //   });
  };

  const chargePayment = async (
    post,
    subscriptionId,
    customerId,
    paymentIntent
  ) => {
    let formValues = post;
    let newData = {
      ...formValues,
      // payment_intent: paymentIntent,
      subscriptionId: subscriptionId,
      customer_id: customerId,
      plan_id: plan_id,
      subscRef: subscRef
    };

    dispatch(makeSubsPayment(newData));
  };

  return (
    <>
      <section
        id="logon"
        className="sub_scription"
        style={{
          backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`
        }}
      >
        <div className="contain">
          <div className="flex_row main_row row">
            <div className="col col-lg-5 left_col_sub">
              <div className="content">
                <h3 className="tag">{content.banner_heading}</h3>
                <h2 className="heading membership_head_flex">
                  <em>£{plan.price}</em>{" "}
                  <small className="mini_membership_text">
                    {plan.id == 1
                      ? "(billed monthly)"
                      : `(£${plan.price_per_month}/month, billed ${plan.plan_interval})`}
                  </small>
                </h2>
                <h5>{plan.plan_name} Subscription</h5>
                <div className="account">
                  <ul>
                    {plan.selected_offers &&
                      plan.selected_offers.map((row) => <li>{row}</li>)}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-lg-5">
              <div className="in_col">
                <form
                  action=""
                  method="POST"
                  onSubmit={handleSubmit(handleFormSubmit)}
                >
                  <div className="log_blk">
                    <div className="txt">
                      <div className="or_line">
                        <span>Pay with card</span>
                      </div>
                    </div>
                    <div className="form_row row">
                      {/* <div className="col-sm-6">
                        <h5>First Name*</h5>
                        <div className="form_blk pass_blk">
                          <input
                            type="text"
                            className="input"
                            placeholder="Enter first name."
                            {...register("fname", {
                              required: "First Name is required.",
                              maxLength: {
                                value: 20,
                                message:
                                  "First Name should Not be greater than 20 characters."
                              },
                              minLength: {
                                value: 2,
                                message:
                                  "First Name should be greater than atleast 2 characters."
                              }
                            })}
                          />
                          <span className="validation-error">
                            {errors.fname?.message}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <h5>Last Name*</h5>
                        <div className="form_blk pass_blk">
                          <input
                            type="text"
                            className="input"
                            placeholder="Enter last name"
                            {...register("lname", {
                              required: "Last Name is required.",
                              maxLength: {
                                value: 20,
                                message:
                                  "Last Name should Not be greater than 20 characters."
                              },
                              minLength: {
                                value: 2,
                                message:
                                  "Last Name should be greater than atleast 2 characters."
                              }
                            })}
                          />
                          <span className="validation-error">
                            {errors.lname?.message}
                          </span>
                        </div>
                      </div> */}
                      {/* <div className="col-sm-12">
                        <h5>Email*</h5>
                        <div className="form_blk pass_blk">
                          <input
                            type="text"
                            className="input"
                            placeholder="Enter email"
                            {...register("email", {
                              required: "Email is required.",
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Please enter a valid email"
                              }
                            })}
                          />
                          <span className="validation-error">
                            {errors.email?.message}
                          </span>
                        </div>
                      </div> */}
                      <div className="col-sm-12">
                        <h5>Name on the card</h5>
                        <div className="form_blk pass_blk">
                          <input
                            type="text"
                            className="input"
                            placeholder="Enter the name on the card"
                            {...register("name_on_card", {
                              required: "Name on the card is required."
                            })}
                          />
                          <span className="validation-error">
                            {errors.name_on_card?.message}
                          </span>
                        </div>
                      </div>
                      {/* <div className="col-sm-6">
                        <h5>Phone</h5>
                        <div className="form_blk pass_blk">
                          <input
                            type="text"
                            className="input"
                            placeholder="Enter phone"
                            {...register("phone")}
                          />
                        </div>
                      </div> */}

                      <div className="col-sm-12">
                        <h5>Card number*</h5>
                        <div className="form_blk pass_blk">
                          <div className="new_input">
                            <CardNumberElement options={options} />
                          </div>
                          <span className="validation-error">
                            {errors.cardNumber?.message}
                          </span>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <h5>Expiration Date*</h5>
                        <div className="form_blk pass_blk mrgin_bottom_zero">
                          <div className="new_input">
                            <CardExpiryElement options={options} />
                          </div>
                        </div>
                        <span className="validation-error">{cardError}</span>
                      </div>
                      <div className="col-sm-6">
                        <h5>Card CVC*</h5>
                        <div className="form_blk pass_blk">
                          <div className="new_input">
                            <CardCvcElement options={options} />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <h5>Zip Code*</h5>
                        <div className="form_blk pass_blk">
                          <input
                            type="text"
                            className="input"
                            placeholder="Enter zipcode"
                            {...register("zip_code", {
                              required: "Zipcode is required."
                            })}
                          />
                          <span className="validation-error">
                            {errors.zip_code?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="btn_blk form_btn form_blk">
                      <button
                        type="submit"
                        className="site_btn block"
                        disabled={!stripe || !elements || cardLoading}
                      >
                        <FormProcessingSpinner isFormProcessing={cardLoading} />
                        Pay now
                      </button>
                      <div className="strip_card_image">
                        <img src="/images/payment_strip_new.PNG" alt="" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubscriptionForm;
