import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_JOBS_CONTENT,
  FETCH_JOBS_CONTENT_SUCCESS,
  FETCH_JOBS_CONTENT_FAILED,
  FETCH_JOBS_SEARCH,
  FETCH_JOBS_SEARCH_SUCCESS,
  FETCH_JOBS_SEARCH_FAILED,
  SAVE_JOB,
  SAVE_JOB_SUCCESS,
  SAVE_JOB_FAILED,
  JOB_APPLIED,
  JOB_APPLIED_SUCCESS,
  JOB_APPLIED_FAILED,
  REPORT_AN_JOB,
  REPORT_AN_JOB_SUCCESS,
  REPORT_AN_JOB_FAILED,
  REPORT_AN_JOB_POPUP
} from "./actionTypes";

export const fetchJobs = () => (dispatch) => {
  dispatch({
    type: FETCH_JOBS_CONTENT,
    payload: null
  });
  http
    .post(
      "jobs",
      helpers.doObjToFormData({ token: localStorage.getItem("authToken") })
    )
    .then(({ data }) => {
      dispatch({
        type: FETCH_JOBS_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_JOBS_CONTENT_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};

export const searchJobsData = (post) => (dispatch) => {
  post = { ...post, token: localStorage.getItem("authToken") };
  dispatch({
    type: FETCH_JOBS_SEARCH,
    payload: null
  });
  http
    .post("fetch-jobs-data", helpers.doObjToFormData(post))
    .then(({ data }) => {
      // document.getElementById("job__block").scrollIntoView();
      window.scrollTo({ top: 300, behavior: "smooth" });
      dispatch({
        type: FETCH_JOBS_SEARCH_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_JOBS_SEARCH_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};

export const saveJobAction = (formData) => (dispatch) => {
  let jobId = formData.id;
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: SAVE_JOB,
      payload: null
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("save-job", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          data = { ...data, id: jobId };
          toast.success("Job Saved Successfully.", TOAST_SETTINGS);
          dispatch({
            type: SAVE_JOB_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SAVE_JOB_FAILED,
            payload: null
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SAVE_JOB_FAILED,
          payload: error
        });
        const { data } = error.response;
        localStorage.removeItem("authToken");
        window.location.reload();
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};

export const applyJob = (formData) => (dispatch) => {
  let jobId = formData.id;
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: JOB_APPLIED,
      payload: null
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("user/job-applied", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          data = { ...data, id: jobId };
          toast.success("Job applied successfully.", TOAST_SETTINGS);
          dispatch({
            type: JOB_APPLIED_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: JOB_APPLIED_FAILED,
            payload: null
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: JOB_APPLIED_FAILED,
          payload: error
        });
        const { data } = error.response;
        localStorage.removeItem("authToken");
        window.location.reload();
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};

export const reportAnJobPopup = () => (dispatch) => {
  dispatch({
    type: REPORT_AN_JOB_POPUP,
    payload: null
  });
};
export const reportAnJob = (formData) => (dispatch) => {
  // let jobId = formData.id;
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: REPORT_AN_JOB,
      payload: null
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("user/report-an-job", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          // data = { ...data, id: jobId };
          toast.success(
            "You have successfully reported the job.",
            TOAST_SETTINGS
          );
          setTimeout(() => {
            dispatch({
              type: REPORT_AN_JOB_SUCCESS,
              payload: data
            });
          }, 800);
        } else {
          dispatch({
            type: REPORT_AN_JOB_FAILED,
            payload: null
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: REPORT_AN_JOB_FAILED,
          payload: error
        });
        const { data } = error.response;
        localStorage.removeItem("authToken");
        window.location.reload();
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};
