import React, { useState } from "react";
import { Link } from "react-router-dom";
import Text from "../../common/Text";
import { useSelector } from "react-redux";
import { getBackgroundImageUrl } from "../../../helpers/helpers";
import FormProcessingSpinner from "../../common/FormProcessingSpinner";
import SocialLinks from "../../includes/SocialLinks";
import { checkReferralLink } from "../../../helpers/helpers";

const Logon = ({ data, content, isFormProcessing, handleSignin }) => {
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const { log, side } = data;
  const [showPass, setShowPass] = useState(false);
  const [formVal, setFormVal] = useState({
    email: "",
    password: "",
    from: "page",
  });
  const inputHandle = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormVal({ ...formVal, [name]: value });
  };
  const passBtn = () => {
    setShowPass(!showPass);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignin(formVal);
  };

  const referralLink = localStorage.getItem("ref");
  const compaign_name = localStorage.getItem("compaign_name");
  return (
    <>
      <section
        id="logon"
        style={{
          backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`,
        }}
      >
        <div className="contain">
          <div className="flex_row main_row row">
            <div className="col col-lg-5">
              <div className="content">
                {/* <h6 className="tag">
                  <Text string={content.banner_tag} />
                </h6> */}
                <h2 className="heading">
                  <Text string={content.banner_heading} />
                </h2>
                <p>
                  <Text string={content.banner_detail} />
                </p>
                {/* <div className="account">
                  <Text string={content.banner_create_heading} />
                  <Link to={content.banner_button_link}>
                    {content.banner_button_title}
                  </Link>
                </div> */}
                {/* <div className="social_logon">
                    <Link to=""><img src="images/new/facebook.svg" alt="" /></Link>
                    <Link to=""><img src="images/new/twitter.svg" alt="" /></Link>
                    <Link to=""><img src="images/new/linkedin.svg" alt="" /></Link>
                </div> */}
                <SocialLinks siteSettings={siteSettings} />
              </div>
            </div>
            <div className="col col-lg-5">
              <div className="in_col">
                <form action="" method="POST" onSubmit={handleSubmit}>
                  <div className="log_blk">
                    <div className="txt">
                      <h2>
                        <Text string={content.right_heading} />
                      </h2>
                      {/* <p>
                        <Text string={content.right_tagline} />
                      </p> */}
                    </div>
                    <div className="form_row row">
                      <div className="col-sm-12">
                        <h5>
                          <Text string={content.first_field_heading} />
                        </h5>
                        <div className="form_blk">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            value={formVal.email}
                            onChange={inputHandle}
                            className="input"
                            placeholder={content.first_field_placeholder}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <h5>
                          <Text string={content.second_field_heading} />
                        </h5>
                        <div className="form_blk pass_blk">
                          <input
                            type={!showPass ? "password" : "text"}
                            name="password"
                            id="password"
                            value={formVal.password}
                            onChange={inputHandle}
                            className="input"
                            placeholder={content.second_field_placeholder}
                          />
                          <i
                            className={
                              !showPass ? "icon-eye" : "icon-eye-slash"
                            }
                            onClick={passBtn}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div className="btn_blk form_btn form_blk">
                      <button
                        type="submit"
                        className="site_btn block"
                        disabled={isFormProcessing}
                      >
                        <FormProcessingSpinner
                          isFormProcessing={isFormProcessing}
                        />
                        <Text string={content.submit_text} />
                      </button>
                    </div>
                    <div className="forgot">
                      <Text string={content.forgot_password} />
                      <Link to={content.forgot_password_link}>
                        {content.forgot_password_heading}
                      </Link>
                    </div>
                    <div className="account mt-2">
                      <Text string={content.account_heading} />
                      <Link
                        to={
                          checkReferralLink(referralLink)
                            ? `${content.account_heading_link}?ref=${referralLink}&compaign_name=${compaign_name}`
                            : `${content.account_heading_link}`
                        }
                        className="color"
                      >
                        {content.account_heading_heading}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Logon;
