import React, { useEffect } from "react";
import Text from "../../common/Text";

const Offers = ({ content }) => {
  return (
    <>
      <div class="text-center sub_scription_heading">
        <h2>
          <Text string={content.banner_heading} />
        </h2>
        <p>
          <Text string={content.banner_tagline} />
        </p>
      </div>
      <div className="flex plan_flex dash_plan">
        <div className="col">
          <ul className="title_sub_lst">
            <li>
              <h4>We Offer</h4>
              <p>Suitable for people who are</p>
            </li>
            <li>Access to the full job list</li>
            <li>Weekly Fearured Jobs</li>
            <li>Live job application workshop</li>
            <li>Live CV writing workshop</li>
            <li>Live interview workshop</li>
            <li>Live assessment centre workshop</li>
            <li>Personalised job application advice</li>
            <li>
              <div className="mini_lbl">
                <span>Cancel any time</span>* On average it takes 3-6 months to
                find a job
              </div>
            </li>
          </ul>
        </div>
        <div className="col">
          <ul className="title_sub_lst">
            <li>
              <h6>Monthly</h6>
              <h4 className="color">£4.99</h4>
              <p>Just exploring what’s out there</p>
            </li>
            <li>
              <div className="green_check_img">
                <span>Access to the full job list</span>
                <img src="images/new/tick-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="green_check_img">
                <span>Weekly Fearured Jobs</span>
                <img src="images/new/tick-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="gray_check_img">
                <span>Live job application workshop</span>
                <img src="images/new/close-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="gray_check_img">
                <span>Live CV writing workshop</span>
                <img src="images/new/close-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="gray_check_img">
                <span>Live interview workshop</span>
                <img src="images/new/close-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="gray_check_img">
                <span>Live assessment centre workshop</span>
                <img src="images/new/close-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="gray_check_img">
                <span>Personalised job application advice</span>
                <img src="images/new/close-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="btn_blk">
                <button className="site_btn">Donwgrade</button>
              </div>
            </li>
          </ul>
        </div>
        <div className="col">
          <ul className="title_sub_lst">
            <li>
              <h6>Quarterly </h6>
              <h4 className="color">£3.99</h4>
              <p>Seriously looking for a job</p>
            </li>
            <li>
              <div className="green_check_img">
                <span>Access to the full job list</span>
                <img src="images/new/tick-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="green_check_img">
                <span>Weekly Fearured Jobs</span>
                <img src="images/new/tick-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="green_check_img">
                <span>Live job application workshop</span>
                <img src="images/new/tick-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="green_check_img">
                <span>Live CV writing workshop</span>
                <img src="images/new/tick-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="green_check_img">
                <span>Live interview workshop</span>
                <img src="images/new/tick-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="green_check_img">
                <span>Live assessment centre workshop</span>
                <img src="images/new/tick-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="gray_check_img">
                <span>Personalised job application advice</span>
                <img src="images/new/close-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="btn_blk">
                <button className="site_btn blank">Current Plan</button>
              </div>
            </li>
          </ul>
        </div>
        <div className="col">
          <ul className="title_sub_lst">
            <li>
              <h6>Biannually </h6>
              <h4 className="color">£2.99</h4>
              <p>Keen to secure a job offer</p>
            </li>
            <li>
              <div className="green_check_img">
                <span>Access to the full job list</span>
                <img src="images/new/tick-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="green_check_img">
                <span>Weekly Fearured Jobs</span>
                <img src="images/new/tick-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="green_check_img">
                <span>Live job application workshop</span>
                <img src="images/new/tick-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="green_check_img">
                <span>Live CV writing workshop</span>
                <img src="images/new/tick-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="green_check_img">
                <span>Live interview workshop</span>
                <img src="images/new/tick-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="green_check_img">
                <span>Live assessment centre workshop</span>
                <img src="images/new/tick-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="green_check_img">
                <span>Personalised job application advice</span>
                <img src="images/new/tick-circle.svg" alt="" />
              </div>
            </li>
            <li>
              <div className="btn_blk">
                <button className="site_btn">Upgrade</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Offers;
