import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_DASHBOARD,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILED,
  //   FETCH_JOBS_SEARCH,
  //   FETCH_JOBS_SEARCH_SUCCESS,
  //   FETCH_JOBS_SEARCH_FAILED,
  SAVE_JOB_STAT,
  SAVE_JOB_STAT_SUCCESS,
  SAVE_JOB_STAT_FAILED,
  SAVE_APPLIED_JOB_STAT,
  SAVE_APPLIED_JOB_STAT_SUCCESS,
  SAVE_APPLIED_JOB_STAT_FAILED,
  MARK_AS_APPLIED,
  MARK_AS_APPLIED_SUCCESS,
  MARK_AS_APPLIED_FAILED,
  DELETE_JOB,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILED,
} from "./actionTypes";
import { Navigate } from "react-router-dom";
import RedirectToSignIn from "../../components/common/RedirectToSignIn";

export const fetchDashboard = () => (dispatch) => {
  dispatch({
    type: FETCH_DASHBOARD,
    payload: null,
  });
  http
    .post(
      "user/dashboard",
      helpers.doObjToFormData({ token: localStorage.getItem("authToken") })
    )
    .then(({ data }) => {
      dispatch({
        type: FETCH_DASHBOARD_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_DASHBOARD_FAILED,
        payload: error,
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
      // <RedirectToSignIn />;
    });
};

// export const searchJobsData = (post) => (dispatch) => {
//   dispatch({
//     type: FETCH_JOBS_SEARCH,
//     payload: null
//   });
//   http
//     .post("fetch-jobs-data", helpers.doObjToFormData(post))
//     .then(({ data }) => {
//       dispatch({
//         type: FETCH_JOBS_SEARCH_SUCCESS,
//         payload: data
//       });
//     })
//     .catch((error) => {
//       dispatch({
//         type: FETCH_JOBS_SEARCH_FAILED,
//         payload: error
//       });
//     });
// };

export const saveJobStat = (formData) => (dispatch) => {
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: SAVE_JOB_STAT,
      payload: null,
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("user/save-job-stat", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          toast.success("Saved Successfully.", TOAST_SETTINGS);
          dispatch({
            type: SAVE_JOB_STAT_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: SAVE_JOB_STAT_FAILED,
            payload: null,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SAVE_JOB_STAT_FAILED,
          payload: error,
        });
        const { data } = error.response;
        localStorage.removeItem("authToken");
        window.location.reload();
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};

export const saveAppliedJobStat = (formData) => (dispatch) => {
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: SAVE_APPLIED_JOB_STAT,
      payload: null,
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("user/applied-job-status", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          toast.success("Saved Successfully.", TOAST_SETTINGS);
          dispatch({
            type: SAVE_APPLIED_JOB_STAT_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: SAVE_APPLIED_JOB_STAT_FAILED,
            payload: null,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SAVE_APPLIED_JOB_STAT_FAILED,
          payload: error,
        });
        const { data } = error.response;
        localStorage.removeItem("authToken");
        window.location.reload();
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};

export const markAsApplied = (formData) => (dispatch) => {
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: MARK_AS_APPLIED,
      payload: null,
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("user/mark-as-applied", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          toast.success("Moved To Applied Successfully.", TOAST_SETTINGS);
          dispatch({
            type: MARK_AS_APPLIED_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: MARK_AS_APPLIED_FAILED,
            payload: null,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: MARK_AS_APPLIED_FAILED,
          payload: error,
        });
        const { data } = error.response;
        localStorage.removeItem("authToken");
        window.location.reload();
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};

export const deleteJob = (formData) => (dispatch) => {
  if (localStorage.getItem("authToken")) {
    dispatch({
      type: DELETE_JOB,
      payload: null,
    });
    formData = { ...formData, token: localStorage.getItem("authToken") };
    http
      .post("user/delete-job", helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          toast.success("Job Removed Successfully.", TOAST_SETTINGS);
          dispatch({
            type: DELETE_JOB_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: DELETE_JOB_FAILED,
            payload: null,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_JOB_FAILED,
          payload: error,
        });
        const { data } = error.response;
        localStorage.removeItem("authToken");
        window.location.reload();
      });
  } else {
    toast.error("Please signin first to save this job post.", TOAST_SETTINGS);
  }
};
