import React, { useEffect, useState, useRef } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import {
  fetchJobProfile,
  applyJob,
  saveJobAction
} from "../../../states/actions/fetchJobProfile";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { ToastContainer } from "react-toastify";
import Text from "../../common/Text";
import { Link, useParams } from "react-router-dom";
import ImageControl from "../../common/ImageControl";
import {
  jobProgressColor,
  makeSalaryString,
  doFirstUpperRestLower
} from "../../../helpers/helpers";
import { useForm } from "react-hook-form";
import FormProcessingSpinner from "../../common/FormProcessingSpinner";

const Apply = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchJobProfile.content);
  const isLoading = useSelector((state) => state.fetchJobProfile.isLoading);
  const job = useSelector((state) => state.fetchJobProfile.job);
  const isFormProcessing = useSelector(
    (state) => state.fetchJobProfile.isFormProcessing
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const cvRef = useRef();
  const coverRef = useRef();
  const [cv, setCv] = useState(null);
  const [cvError, setCvError] = useState(false);
  const [coverError, setCoverError] = useState(false);
  const [cover, setCover] = useState(null);

  const { countries } = data;

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm();

  const firstSubmit = (data) => {
    setCvError(false);
    setCoverError(false);
    if (cv !== null) {
      data.cv = cv.target.files;
    } else {
      setCvError(true);
      return false;
    }

    if (cover !== null) data.cover = cover.target.files;

    data.job_id = id;
    dispatch(applyJob(data));
  };

  const handleCvClick = (e) => {
    e.preventDefault();
    setCv(null);
    cvRef.current.click();
  };

  const handleCoverClick = (e) => {
    e.preventDefault();
    setCover(null);
    coverRef.current.click();
  };

  const handleSelectFile = (e) => {
    setCv(e);
  };

  const handleSelectCover = (e) => {
    setCover(e);
  };

  const handleSaveJob = (id) => {
    dispatch(saveJobAction({ id: id }));
  };

  useEffect(() => {
    dispatch(fetchJobProfile({ id }));
  }, []);

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <section className="job_outer new_profile_job apply_job_form">
            <div className="banner_blk"></div>
            <div className="contain job_inner">
              <div className="flex">
                <div className="colL">
                  <div className="sm_icon">
                    {job.image == "" || job.image == null ? (
                      <img src="/images/no-image.svg" alt="no image found" />
                    ) : (
                      <ImageControl
                        src={job.image}
                        folder="companies"
                        isThumb={true}
                      />
                    )}
                  </div>
                  <div className="cntnt">
                    <h5>
                      <Text string={job.title} />
                    </h5>
                    <p>{job.company_name}</p>
                    <ul className="specific_info">
                      <li>
                        <img src="/images/new/location.svg" alt="" />{" "}
                        <span>
                          <div>{job.city}</div>
                        </span>
                      </li>
                      <li>
                        <img src="/images/new/teacher.svg" alt="" />{" "}
                        <span>
                          <div>{job.degree_requirement}</div>
                        </span>
                      </li>
                      <li>
                        <img
                          class="sm_icon_pound"
                          src="/images/pound1.png"
                          alt=""
                        />{" "}
                        <span>
                          {makeSalaryString(
                            job.min_salary,
                            job.max_salary,
                            doFirstUpperRestLower(job.salary_interval)
                          )}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="detail_jobs">
                    <h5>Job Summary</h5>
                    <Text string={job.description} />
                  </div>
                  {/*<div className="br"></div>
                  <div class="btn_blk">
                    <button class="site_btn ">
                      Apply Now <i className="arrow"></i>
                    </button>
                  </div> */}
                </div>
                <div className="colR apply_colR_form">
                  <div className="outer_form_blk">
                    {job.saved ? (
                      "You have saved this job."
                    ) : (
                      <button
                        class="site_btn blank"
                        onClick={() => handleSaveJob(job.id)}
                      >
                        Save this job{" "}
                        <img src="/images/new/archive-add1.svg" alt="" />
                      </button>
                    )}
                    <br />
                    {job.applied ? (
                      "You have applied for this job."
                    ) : (
                      <form method="POST" onSubmit={handleSubmit(firstSubmit)}>
                        <h5>Basic Information </h5>
                        <div className="form_row row">
                          <div class="col-sm-6 col-xs-6">
                            <h6>First name</h6>
                            <div className="form_blk">
                              <input
                                type="text"
                                className="input"
                                {...register("fname", {
                                  required: "First Name is required.",
                                  minLength: {
                                    value: 2,
                                    message:
                                      "First Name should contains atleast 2 letters."
                                  }
                                })}
                              />
                              <span className="validation-error">
                                {errors.fname?.message}
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-6 col-xs-6">
                            <h6>Last name</h6>
                            <div className="form_blk">
                              <input
                                type="text"
                                className="input"
                                {...register("lname", {
                                  required: "Last Name is required.",
                                  minLength: {
                                    value: 2,
                                    message:
                                      "Last Name should contains atleast 2 letters."
                                  }
                                })}
                              />
                              <span className="validation-error">
                                {errors.lname?.message}
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-12 col-xs-12">
                            <h6>Email address</h6>
                            <div className="form_blk">
                              <input
                                type="text"
                                className="input"
                                {...register("email", {
                                  required: "Email is required.",
                                  pattern: {
                                    value:
                                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Please enter a valid email"
                                  }
                                })}
                              />
                              <span className="validation-error">
                                {errors.email?.message}
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-12 col-xs-12">
                            <h6>Linkedin Profile (Optional)</h6>
                            <div className="form_blk">
                              <input
                                type="text"
                                className="input"
                                {...register("linkedin_profile")}
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-xs-12">
                            <h6>
                              Phone number <span>(Optional)</span>
                            </h6>
                            <PhoneInput
                              defaultCountry="GB"
                              className="input"
                              {...register("phone")}
                            />
                          </div>
                          <div class="col-sm-12 col-xs-12">
                            <h6>Country of Residence</h6>
                            <div className="form_blk">
                              <select
                                className="input"
                                {...register("country")}
                              >
                                <option value="">Select</option>
                                {countries &&
                                  countries.map((country) => (
                                    <option value={country.id}>
                                      {country.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="br"></div>
                        <h5>Documents</h5>
                        <div className="form_row row">
                          <div class="col-sm-12 col-xs-12">
                            <h6>Please upload your CV</h6>
                            <div className="upload_blk" onClick={handleCvClick}>
                              <div className="upload_lbl flex">
                                <img src="/images/new/gray_upload.svg" alt="" />
                                <span>
                                  {cv
                                    ? cv?.target?.files[0]?.name
                                    : "Upload your CV"}
                                </span>
                              </div>
                            </div>
                            {cvError && (
                              <span className="validation-error">
                                Please select cv.
                              </span>
                            )}
                            <input
                              type="file"
                              className="hidden"
                              hidden
                              ref={cvRef}
                              onChange={handleSelectFile}
                            />
                          </div>
                          <div class="col-sm-12 col-xs-12">
                            <h6>Please upload your Cover Letter </h6>
                            <div
                              className="upload_blk"
                              onClick={handleCoverClick}
                            >
                              <div className="upload_lbl flex">
                                <img src="/images/new/gray_upload.svg" alt="" />
                                <span>
                                  {cover
                                    ? cover?.target?.files[0]?.name
                                    : "Upload your Cover Letter"}
                                </span>
                              </div>
                            </div>
                            {coverError && (
                              <span className="validation-error">
                                Please select cover letter.
                              </span>
                            )}
                            <input
                              type="file"
                              className="hidden"
                              hidden
                              ref={coverRef}
                              onChange={handleSelectCover}
                            />
                          </div>
                        </div>
                        <div className="br"></div>
                        <div className="lbl_btn">
                          <input
                            type="checkbox"
                            id="confirm"
                            {...register("confirm", {
                              required: "Please accept terms and conditions."
                            })}
                          />
                          <label htmlFor="confirm">
                            By ticking the checkbox, I accept the
                            <Link target="_blank" to="/terms-conditions">
                              &nbsp;T&C&nbsp;
                            </Link>
                            and &nbsp;
                            <Link target="_blank" to="/privacy-policy">
                              Privacy Policy.
                            </Link>
                          </label>
                        </div>
                        <span className="validation-error">
                          {errors.confirm?.message}
                        </span>
                        <button
                          className="site_btn full_wide_btn"
                          disabled={isFormProcessing}
                        >
                          Submit Application
                          <FormProcessingSpinner
                            isFormProcessing={isFormProcessing}
                          />
                        </button>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default Apply;
