import React, { useState } from "react";
import FaqBlk from "../../common/FaqBlk";
import Text from "../../common/Text";

const Accordion = ({ content, faqs, faq2s }) => {
  const [section, setSection] = useState(1);
  const [list, setList] = useState(faqs);
  const [selected, setSelected] = useState(list?.[0]?.id);

  const handleSetSection = (s) => {
    setSection(s);
    if (s === 1) setList(faqs);
    else setList(faq2s);
  };

  const handleSetAccordian = (id) => {
    if (selected == id) setSelected(0);
    else setSelected(id);
  };
  return (
    <>
      <section id="faq">
        <div className="contain">
          <div className="faq_opt text-center">
            <span
              className={section === 1 && "active"}
              onClick={() => handleSetSection(1)}
            >
              <Text string={content.sec2_heading} />
            </span>
            <span
              className={section === 2 && "active"}
              onClick={() => handleSetSection(2)}
            >
              <Text string={content.sec2_2_heading} />
            </span>
          </div>
          <div className="faq_lst">
            {list.map((f) => {
              return (
                <FaqBlk
                  f={f}
                  key={f.id}
                  selected={selected}
                  handleSetAccordian={handleSetAccordian}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Accordion;
