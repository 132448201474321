import React from "react";
import Text from "./Text";

function Cover({content }) {
  return (
    <>
      <section id="job_intro" className="all_banner">
        <div className="contain">
          <div className="content">
            <h1 className="heading">
              <Text string={content.banner_heading} parse={true} />
            </h1>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cover;
