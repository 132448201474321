import {
  FETCH_DASHBOARD_SUBSCRIPTION,
  FETCH_DASHBOARD_SUBSCRIPTION_SUCCESS,
  FETCH_DASHBOARD_SUBSCRIPTION_FAILED,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILED,
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  isCancelFormProcessing: false,
  content: {},
  subscribeArr: null,
  error: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_DASHBOARD_SUBSCRIPTION:
      return {
        ...state,
        isLoading: true,
        content: {},
      };
    case FETCH_DASHBOARD_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
        subscribeArr: payload.subscribeArr,
      };
    case FETCH_DASHBOARD_SUBSCRIPTION_FAILED:
      return {
        ...state,
        isLoading: false,
        content: {},
        error: payload,
      };
    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        isCancelFormProcessing: true,
      };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isCancelFormProcessing: false,
        subscribeArr: null,
      };
    case CANCEL_SUBSCRIPTION_FAILED:
      return {
        ...state,
        isCancelFormProcessing: false,
        error: payload,
      };

    default:
      return state;
  }
}
