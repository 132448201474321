import React, { useState, useRef, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import SearchBar from "../pages/dashboard/SearchBar";
import ImageControl from "../common/ImageControl";
import { websiteLink } from "../../helpers/helpers";

function LoggedHeader({ memData }) {
  const [dropDownOne, setDropdownOne] = useState(false);
  const firstDropdown = () => {
    setDropdownOne(!dropDownOne);
  };

  const dropOneRef = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropOneRef.current && !dropOneRef.current.contains(e.target)) {
        setDropdownOne(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("authToken");
    window.location.replace("/signin");
  };

  return (
    <>
      <nav className="ease">
        <div className="right_logged_nav">
          <ul>
            <li>
              <Link to="#">
                <img src="images/new/notification.svg" alt="" />
              </Link>
            </li>
            <li className="drop" onClick={firstDropdown} ref={dropOneRef}>
              <button className="drop_btn">
                <div className="flex">
                  <div className="img_icon">
                    {memData && memData.mem_image ? (
                      <ImageControl
                        src={memData.mem_image}
                        folder="members"
                        isThumb={true}
                      />
                    ) : (
                      <img src="/images/new/frame.svg" alt="no image found" />
                    )}
                  </div>
                  <div className="cntnt">
                    <h6>
                      {memData &&
                        `${
                          memData.mem_fname === "" || memData.mem_fname === null
                            ? ""
                            : memData.mem_fname
                        } ${
                          memData.mem_lname === "" || memData.mem_lname === null
                            ? ""
                            : memData.mem_lname
                        }`}
                    </h6>
                    <p>{memData && `${memData.mem_email}`}</p>
                  </div>
                </div>
                <div class="drop_arrow">
                  <img src="images/new/arrow-down-filter.svg" alt="" />
                </div>
                <div className={dropDownOne ? "drop_cnt active" : "drop_cnt"}>
                  <ul className="drop_lst">
                    <li>
                      <Link to={websiteLink("/")}>Home</Link>
                    </li>
                    <li>
                      <Link to="/profile-settings">Account Settings</Link>
                    </li>
                    <li>
                      <a href="javascript:void(0)" onClick={logout}>
                        Sign out
                      </a>
                    </li>
                  </ul>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default LoggedHeader;
