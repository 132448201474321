import {
  FETCH_JOB_PROFILE,
  FETCH_JOB_PROFILE_SUCCESS,
  FETCH_JOB_PROFILE_FAILED,
  APPLY_INTERNAL_JOB,
  APPLY_INTERNAL_JOB_SUCCESS,
  APPLY_INTERNAL_JOB_FAILED,
  SAVE_JOB_INTERNAL,
  SAVE_JOB_INTERNAL_SUCCESS,
  SAVE_JOB_INTERNAL_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  isFormProcessing: false,
  job: null,
  content: {},
  error: false,
  isJobSaving: false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_JOB_PROFILE:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_JOB_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        job: payload.job,
        content: payload
      };
    case FETCH_JOB_PROFILE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case APPLY_INTERNAL_JOB:
      return {
        ...state,
        isFormProcessing: true
      };
    case APPLY_INTERNAL_JOB_SUCCESS:
      return {
        ...state,
        isFormProcessing: false,
        job: payload.job
      };
    case APPLY_INTERNAL_JOB_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    case SAVE_JOB_INTERNAL:
      return {
        ...state,
        isJobSaving: true
      };
    case SAVE_JOB_INTERNAL_SUCCESS:
      return {
        ...state,
        isJobSaving: false,
        job: payload.job
      };
    case SAVE_JOB_INTERNAL_FAILED:
      return {
        ...state,
        isJobSaving: false,
        error: payload
      };
    default:
      return state;
  }
}
