import React, { useState } from "react";
import { Link } from "react-router-dom";
import ImageControl from "../../common/ImageControl";
import Text from "../../common/Text";
import {
  doFirstUpperRestLower,
  makeSalaryString
} from "../../../helpers/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  saveAppliedJobStat,
  markAsApplied,
  deleteJob
} from "../../../states/actions/fetchDashboard";
import { useSelector, useDispatch } from "react-redux";
import { jobProgressColor } from "../../../helpers/helpers";

const JobsEvents = ({ jobs, applied_job_actions, jobsType }) => {
  if (jobs.length) console.log(jobs);
  const dispatch = useDispatch();
  const isAppliedJobStatUpdating = useSelector(
    (state) => state.fetchDashboard.isAppliedJobStatUpdating
  );
  const isMarkAsAppliedProcessing = useSelector(
    (state) => state.fetchDashboard.isMarkAsAppliedProcessing
  );
  const isJobDeleting = useSelector(
    (state) => state.fetchDashboard.isJobDeleting
  );

  const handleSaveJobStat = (e, id) => {
    dispatch(saveAppliedJobStat({ action: e.target.value, id }));
  };

  const handleMarkAsApplied = (id) => {
    dispatch(markAsApplied({ id }));
  };

  const handleDeleteJob = (formData) => {
    dispatch(deleteJob(formData));
  };

  return (
    <>
      <div className="job_outer">
        {!jobs.length ? (
          <p>
            {jobsType == 1
              ? "The jobs that you have applied will show here."
              : "The jobs that you have saved will show here."}
          </p>
        ) : (
          jobs.map((job) => (
            <div className="job_inner">
              <div className="flex">
                <Link to="" className="img_icon">
                  <Link to="" className="img_icon">
                    {job.image == "" || job.image == null ? (
                      <img src="/images/no-image.svg" alt="no image found" />
                    ) : (
                      <ImageControl
                        src={job.image}
                        folder="companies"
                        isThumb={true}
                      />
                    )}
                  </Link>
                </Link>
                <div className="cntnt">
                  <h5>
                    {job.is_internal_or_external === "external" ? (
                      <a href={job.job_link} target="_blank">
                        <Text string={job.title} />
                      </a>
                    ) : (
                      <Link to={`/job-profile/${job.id}`}>
                        <Text string={job.title} />
                      </Link>
                    )}
                  </h5>
                  <p>
                    <Text string={job.company_name} />
                  </p>
                  <ul className="specific_info">
                    <li>
                      <img src="images/new/location.svg" alt="" />{" "}
                      <span>
                        {" "}
                        <Text string={job.city} />
                      </span>
                    </li>
                    <li>
                      <img src="images/new/teacher.svg" alt="" />{" "}
                      <span>
                        <Text
                          string={`${
                            job.degree_requirement == "no-minimum"
                              ? "No Mminimum"
                              : job.degree_requirement
                          }`}
                        />
                      </span>
                    </li>
                    <li>
                      <img
                        className="sm_icon_pound"
                        src="images/pound1.png"
                        alt=""
                      />{" "}
                      <span>
                        {makeSalaryString(
                          job.min_salary,
                          job.max_salary,
                          doFirstUpperRestLower(job.salary_interval)
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="dash_action">
                <div className="del_action">
                  <button
                    onClick={
                      jobsType == 1
                        ? () =>
                            handleDeleteJob({
                              id: job.encoded_id,
                              from: "applied"
                            })
                        : () =>
                            handleDeleteJob({
                              id: job.encoded_id,
                              from: "saved"
                            })
                    }
                    disabled={isJobDeleting}
                  >
                    Delete
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
                {jobsType == 1 ? (
                  <select
                    value={job.job_status}
                    onChange={(e) => handleSaveJobStat(e, job.encoded_id)}
                    disabled={isAppliedJobStatUpdating}
                    className={
                      "status_drop " +
                      jobProgressColor(job.job_status) +
                      "_drop"
                    }
                  >
                    {applied_job_actions &&
                      applied_job_actions.map((row) => (
                        <option value={row.key}>{row.value}</option>
                      ))}
                  </select>
                ) : (
                  <button
                    className="mark_complete_lbl"
                    onClick={() => handleMarkAsApplied(job.encoded_id)}
                    disabled={isMarkAsAppliedProcessing}
                  >
                    Mark As Applied
                    <FontAwesomeIcon icon={faCheckSquare} />
                  </button>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default JobsEvents;
