import React, { useEffect, useState } from "react";
import Banner from "./Banner";
import Brands from "./Brands";
import Joblisting from "./Job-listing";
import Events from "./Events";
import News from "./News";

import {
  fetchHome,
  saveJobActionFirst,
  saveJobActionSecond,
  applyJobFirst,
  applyJobSecond,
  reportAnJob,
  reportAnJobPopup
} from "../../../states/actions/fetchHome";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import useReferralLink from "../../../hooks/useReferralLink";
import LoadingScreen from "../../common/LoadingScreen";
import { ToastContainer } from "react-toastify";
import SigninPopup from "../../common/SiginPopup";
import { useLocation, useSearchParams } from "react-router-dom";

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const referralLink = useReferralLink(
    searchParams.get("ref"),
    searchParams.get("compaign_name")
  );
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.fetchSignin.authToken);
  const location = useLocation();

  const data = useSelector((state) => state.fetchHome.content);
  const isLoading = useSelector((state) => state.fetchHome.isLoading);
  const isJobSavingFirst = useSelector(
    (state) => state.fetchHome.isJobSavingFirst
  );
  const isJobSavingSecond = useSelector(
    (state) => state.fetchHome.isJobSavingSecond
  );
  const isJobApplyingFirst = useSelector(
    (state) => state.fetchHome.isJobApplyingFirst
  );
  const isJobApplyingSecond = useSelector(
    (state) => state.fetchHome.isJobApplyingSecond
  );
  const isJobReporting = useSelector((state) => state.fetchHome.isJobReporting);
  const student_jobs = useSelector((state) => state.fetchHome.student_jobs);
  const experienced_jobs = useSelector(
    (state) => state.fetchHome.experienced_jobs
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );

  const showReportPopup = useSelector(
    (state) => state.fetchHome.showReportPopup
  );

  const [showSigninPopup, setShowSigninPopup] = useState(false);
  const {
    content,
    partners,
    candidates_images,
    events,
    articles,
    totalJobs,
    totalEmployees
  } = data;

  useEffect(() => {
    dispatch(fetchHome({ ref: referralLink }));
  }, []);

  const saveJobFirst = (formData) => {
    if (ifNotLoggedIn()) dispatch(saveJobActionFirst(formData));
  };

  const saveJobSecond = (formData) => {
    if (ifNotLoggedIn()) dispatch(saveJobActionSecond(formData));
  };

  const handleApplyJobFirst = (id) => {
    if (ifNotLoggedIn()) dispatch(applyJobFirst({ id: id }));
  };

  const handleApplyJobSecond = (id) => {
    if (ifNotLoggedIn()) dispatch(applyJobSecond({ id: id }));
  };

  const handleReportAnJob = (formData) => {
    if (ifNotLoggedIn()) dispatch(reportAnJob(formData));
  };

  const handleShowSigninPopup = () => {
    setShowSigninPopup(!showSigninPopup);
  };

  const handleToggleReportPopup = () => {
    if (ifNotLoggedIn()) dispatch(reportAnJobPopup());
  };

  const ifNotLoggedIn = () => {
    if (authToken) return true;
    else;
    {
      localStorage.setItem("fromHome", true);
      window.location.replace("/signin");
      return false;
    }
  };

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <Banner
            content={content}
            candidates_images={candidates_images}
            totalJobs={totalJobs}
            totalEmployees={totalEmployees}
          />
          <Brands partners={partners} />
          <Joblisting
            content={content}
            student_jobs={student_jobs}
            experienced_jobs={experienced_jobs}
            saveJobFirst={saveJobFirst}
            isJobSavingFirst={isJobSavingFirst}
            saveJobSecond={saveJobSecond}
            isJobSavingSecond={isJobSavingSecond}
            handleApplyJobFirst={handleApplyJobFirst}
            handleApplyJobSecond={handleApplyJobSecond}
            isJobApplyingFirst={isJobApplyingFirst}
            isJobApplyingSecond={isJobApplyingSecond}
            isJobReporting={isJobReporting}
            handleReportAnJob={handleReportAnJob}
            handleShowSigninPopup={handleShowSigninPopup}
            showSigninPopup={showSigninPopup}
            showReportPopup={showReportPopup}
            handleToggleReportPopup={handleToggleReportPopup}
          />
          <Events content={content} events={events} />
          <News content={content} articles={articles} />
        </>
      )}
      {showSigninPopup && (
        <SigninPopup handleShowSigninPopup={handleShowSigninPopup} />
      )}
    </>
  );
};

export default Home;
