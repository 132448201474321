import React, { useState } from "react";
import Text from "../../common/Text";
import WithdrawEarningsPopup from "../../common/WithdrawEarningsPopup";

const Bar = ({ data }) => {
  const { content } = data;
  const [popupText, setPopupText] = useState(false);
  const [withdrawEarningsPopup, setWithdrawEarningsPopup] = useState(false);

  const PopupTextActive = () => {
    setPopupText(!popupText);
  };

  const toggleWithdrawEarningPopup = () => {
    setWithdrawEarningsPopup(!withdrawEarningsPopup);
  };

  return (
    <>
      <div className="bar_top">
        <h4 className="heading">
          <Text string={content.banner_heading} />
        </h4>
        <div className="mini_flex_bar flex">
          <h5 className="color" onClick={() => PopupTextActive()}>
            <Text string={content.popup_link_heading} />
          </h5>
          <div className="site_blk">
            <button
              className="site_btn blank"
              onClick={toggleWithdrawEarningPopup}
            >
              Withdraw Earnings
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          popupText ? "popup lg text_popup active" : "popup lg text_popup"
        }
      >
        <div className="table_dv">
          <div className="table_cell">
            <div className="contain">
              <div className="_inner">
                <button
                  type="button"
                  className="x_btn"
                  onClick={() => PopupTextActive()}
                ></button>
                <div className="text-center heading_text">
                  <h2>
                    <Text string={content.popup_heading} />
                  </h2>
                </div>
                <div className="ckEditor text_popup">
                  <Text string={content.popup_detail} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {withdrawEarningsPopup && (
        <WithdrawEarningsPopup
          dismiss={toggleWithdrawEarningPopup}
          data={data}
        />
      )}
    </>
  );
};

export default Bar;
