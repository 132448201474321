import React, { useState } from "react";
import { signinPopup, fetchSignin } from "../../states/actions/fetchSignin";
import { useSelector, useDispatch } from "react-redux";
import FormProcessingSpinner from "./FormProcessingSpinner";
import { ToastContainer } from "react-toastify";

function SigninPopup({ handleShowSigninPopup }) {
  const dispatch = useDispatch();

  const isFormProcessing = useSelector(
    (state) => state.fetchSignin.isFormProcessing
  );

  const [showPass, setShowPass] = useState(false);
  const [formVal, setFormVal] = useState({
    email: "",
    password: "",
    from: "popup"
  });
  const inputHandle = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormVal({ ...formVal, [name]: value });
  };
  const passBtn = () => {
    setShowPass(!showPass);
  };

  const handleSignin = (e) => {
    e.preventDefault();
    // console.log(formVal);
    dispatch(signinPopup(formVal));
  };

  return (
    <>
      <ToastContainer />
      <section className="popup sm" style={{ display: "block" }}>
        <div className="table_dv">
          <div className="table_cell">
            <div className="contain">
              <div className="_inner">
                <button
                  type="button"
                  className="x_btn"
                  onClick={handleShowSigninPopup}
                ></button>
                <h3>Signin Required.</h3>
                <form method="POST" onSubmit={handleSignin}>
                  <div className="form_row row">
                    <div className="col-sm-12">
                      <h5>Email Address</h5>
                      <div className="form_blk">
                        <input
                          type="text"
                          name="email"
                          id="email"
                          value={formVal.email}
                          onChange={inputHandle}
                          className="input"
                          placeholder="eg: sample@gmail.com"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <h5>Password</h5>
                      <div className="form_blk pass_blk">
                        <input
                          type={!showPass ? "password" : "text"}
                          name="password"
                          id="password"
                          value={formVal.password}
                          onChange={inputHandle}
                          className="input"
                          placeholder="eg: PassLogin%7"
                        />
                        <i
                          className={!showPass ? "icon-eye" : "icon-eye-slash"}
                          onClick={passBtn}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="btn_blk form_btn form_blk">
                    <button
                      type="submit"
                      className="site_btn block"
                      disabled={isFormProcessing}
                    >
                      <FormProcessingSpinner
                        isFormProcessing={isFormProcessing}
                      />
                      Signin
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SigninPopup;
