import http from "../../helpers/http";
import httpBlob from "../../helpers/http-blob";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_ARTICLE,
  FETCH_ARTICLE_SUCCESS,
  FETCH_ARTICLE_FAILED
} from "./actionTypes";

export const fetchArticle = (id) => (dispatch) => {
  dispatch({
    type: FETCH_ARTICLE,
    payload: null
  });
  http
    .post(
      "user/fetch-article",
      helpers.doObjToFormData({
        token: localStorage.getItem("authToken"),
        id: id
      })
    )
    .then(({ data }) => {
      dispatch({
        type: FETCH_ARTICLE_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_ARTICLE_FAILED,
        payload: error
      });
      const { data } = error.response;
      localStorage.removeItem("authToken");
      window.location.reload();
    });
};
