import React, { useEffect } from "react";
import ContactUs from "../../common/ContactUs";
import { ToastContainer } from "react-toastify";
import Intro from "./Intro";
import Welcome from "./Welcome";
import Vision from "../../common/Vision";

import {
  fetchAboutUs,
  saveContact
} from "../../../states/actions/fetchAboutUs";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { useSearchParams } from "react-router-dom";
import useReferralLink from "../../../hooks/useReferralLink";

const About = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const referralLink = useReferralLink(
    searchParams.get("ref"),
    searchParams.get("compaign_name")
  );
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchAboutUs.content);
  const isLoading = useSelector((state) => state.fetchAboutUs.isLoading);
  const isFormProcessing = useSelector(
    (state) => state.fetchAboutUs.isFormProcessing
  );
  const { content } = data;
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );

  useEffect(() => {
    dispatch(fetchAboutUs({ ref: referralLink }));
  }, []);

  const handleContactSubmit = (formData) => {
    dispatch(saveContact(formData));
  };

  useDocumentTitle(data.page_title);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <Intro content={content} />
          <Welcome content={content} />
          <Vision content={content} />
          <ContactUs
            content={content}
            handleContactSubmit={handleContactSubmit}
            isFormProcessing={isFormProcessing}
          />
        </>
      )}
    </>
  );
};

export default About;
