import React, { useState } from "react";
import Text from "../../common/Text";

const FaqList = ({ content, faqs }) => {
  const [selected, setSelected] = useState(faqs?.[0]?.id);
  return (
    <>
      <section className="faq_subscription">
        <div className="contain">
          <div className="sec_heading text-center">
            <h2>
              <Text string={content.sec2_heading} />
            </h2>
          </div>
          <div className="faq_lst">
            {faqs &&
              faqs.map((f) => (
                <div
                  className={selected === f.id ? "faq_blk active" : "faq_blk"}
                >
                  <h5 className="fancy" onClick={() => setSelected(f.id)}>
                    <div>
                      <Text string={f.title} />
                    </div>
                  </h5>
                  {selected === f.id && (
                    <div className="txt">
                      <Text string={f.detail} />
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqList;
