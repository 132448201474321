import {
  FETCH_AMBASSADOR_PROPGRAMME,
  FETCH_AMBASSADOR_PROPGRAMME_SUCCESS,
  FETCH_AMBASSADOR_PROPGRAMME_FAILED,
  SAVE_REFERRAL_LINK,
  SAVE_REFERRAL_LINK_SUCCESS,
  SAVE_REFERRAL_LINK_FAILED,
  REQUEST_WITHDRAW_EARNINGS,
  REQUEST_WITHDRAW_EARNINGS_SUCCESS,
  REQUEST_WITHDRAW_EARNINGS_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  isFormProcessing: false,
  content: {},
  links: null,
  error: false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_AMBASSADOR_PROPGRAMME:
      return {
        ...state,
        isLoading: true,
        content: {}
      };
    case FETCH_AMBASSADOR_PROPGRAMME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
        links: payload.links
      };
    case FETCH_AMBASSADOR_PROPGRAMME_FAILED:
      return {
        ...state,
        isLoading: false,
        content: {},
        error: payload
      };
    case SAVE_REFERRAL_LINK:
      return {
        ...state,
        isFormProcessing: true
      };
    case SAVE_REFERRAL_LINK_SUCCESS:
      return {
        ...state,
        isFormProcessing: false,
        links: payload.links
      };
    case SAVE_REFERRAL_LINK_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    case REQUEST_WITHDRAW_EARNINGS:
      return {
        ...state,
        isFormProcessing: true
      };
    case REQUEST_WITHDRAW_EARNINGS_SUCCESS:
      return {
        ...state,
        isFormProcessing: false
      };
    case REQUEST_WITHDRAW_EARNINGS_FAILED:
      return {
        ...state,
        isFormProcessing: false
      };

    default:
      return state;
  }
}
