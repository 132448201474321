import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = ({ toggle, active }) => {
  const [show, setShow] = useState("");
  const toggleLink = (id) => {
    if (show === id) {
      setShow("");
    } else {
      setShow(id);
    }
  };

  const authToken = useSelector((state) => state.fetchSignin.authToken);

  const data = {
    title: "Uk Visa Jobs",
    list: [
      {
        id: 1,
        btn: "Jobs",
        btn_link: "/open-jobs"
      },
      {
        id: 2,
        btn: "Resources",
        btn_link: "/blogs"
      },
      {
        id: 3,
        btn: "About Us",
        btn_link: "/"
      }
    ],
    login_lst: [
      {
        id: 1,
        text: "Sign up for free and enjoy all our benefits. With your information you can Sign in and Sign out in our webshop.",
        btn: "Sign Up",
        btn_link: "/signup"
      },
      {
        id: 2,
        text: "Sign in your UK Visa Jobs account here.",
        btn: "Sign In",
        btn_link: "/signin"
      }
    ],
    sm_list: [
      {
        id: 1,
        btn: "FAQ's & Support Center",
        btn_link: "/faq"
      },
      {
        id: 2,
        btn: "Terms And Conditions",
        btn_link: "/terms-conditions"
      },
      {
        id: 3,
        btn: "Privacy Policy",
        btn_link: "/privacy-policy"
      }
    ]
  };
  return (
    <>
      <aside id="sidebar" className={active ? "active" : ""}>
        <div className="inside">
          <h3 className="color">{data.title}</h3>
          {!authToken && (
            <ul className="logon_list_sider">
              {data.login_lst.map((val) => {
                return (
                  <li key={val.id}>
                    <p>{val.text}</p>
                    <Link
                      to={val.btn_link}
                      onClick={toggle}
                      className="site_btn lg"
                    >
                      {val.btn}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
          <ul className="sm_list">
            {authToken && (
              <li key={1000}>
                <Link to="/dashboard" onClick={toggle}>
                  Dashboard
                </Link>
              </li>
            )}
            {data.sm_list.map((val) => {
              return (
                <li key={val.id}>
                  <Link to={val.btn_link} onClick={toggle}>
                    {val.btn}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
