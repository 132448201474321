import React, { useState, useEffect, useRef } from "react";
import Logo from "./Logo";
import Navigation from "./Navigation";
import Sidebar from "./Sidebar";
import DashSidebar from "./DashSidebar";
import { useSelector } from "react-redux";
import LoggedHeader from "./LoggedHeader";
import ImageControl from "../common/ImageControl";
import { Link, useNavigate } from "react-router-dom";
import { checkReferralLink } from "../../helpers/helpers";

function Header({ logged }) {
  const [scroll, setScroll] = useState(false);
  const [toggle, setToggle] = useState(false);
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );

  const navigate = useNavigate();
  const memData = useSelector((state) => state.fetchSiteSettings.memData);
  const authToken = useSelector((state) => state.fetchSignin.authToken);

  const [dropDownOne, setDropdownOne] = useState(false);

  const referralLink = localStorage.getItem("ref");
  const compaign_name = localStorage.getItem("compaign_name");

  const firstDropdown = () => {
    if (authToken) setDropdownOne(!dropDownOne);
    else {
      if (checkReferralLink(referralLink)) {
        navigate(`/signin?ref=${referralLink}&compaign_name=${compaign_name}`);
      } else {
        navigate("/signin");
      }
    }
  };

  const dropOneRef = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropOneRef.current && !dropOneRef.current.contains(e.target)) {
        setDropdownOne(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, [scroll]);
  const ToggleActive = () => {
    setToggle(!toggle);
  };

  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("authToken");
    window.location.replace("/signin");
  };

  return (
    <>
      {siteSettings && !logged ? (
        <header className={scroll ? "fix" : ""}>
          <div className="contain">
            <Logo logo={siteSettings.site_logo} />
            <div
              className="togle_option"
              onClick={firstDropdown}
              ref={dropOneRef}
            >
              <div className="tog_opt">
                <button
                  type="button"
                  className={!toggle ? "toggle" : "toggle active"}
                  // onClick={() => ToggleActive(!toggle)}
                >
                  <span></span>
                </button>
                <div className="_img">
                  {memData && memData.mem_image ? (
                    <ImageControl
                      src={memData.mem_image}
                      folder="members"
                      isThumb={true}
                    />
                  ) : (
                    <img src="/images/new/frame.svg" alt="" />
                  )}
                </div>
              </div>
              <div className={dropDownOne ? "drop_sub active" : "drop_sub"}>
                <ul>
                  {authToken ? (
                    <>
                      <li>
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li>
                        <a href="javascript:void(0)" onClick={logout}>
                          Sign out
                        </a>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link to="/signin" className="site_btn">
                          Sign in
                        </Link>
                      </li>
                      <li>
                        <Link to="/signup" className="site_btn">
                          Sign up
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            <Navigation active={toggle} toggle={ToggleActive} logged={false} />
            <div className="clearfix"></div>
          </div>
          {toggle ? (
            <Sidebar
              active={toggle}
              toggle={ToggleActive}
              className={!toggle ? "" : "active"}
            />
          ) : (
            ""
          )}
        </header>
      ) : (
        <>
          <header className="fix logged">
            <div className="contain-fluid">
              <Logo logo={siteSettings && siteSettings.site_logo} />
              <button
                type="button"
                className={!toggle ? "toggle" : "toggle active"}
                onClick={() => ToggleActive(!toggle)}
              >
                <span></span>
              </button>
              {/* <Navigation logged={true} /> */}
              <LoggedHeader logged={false} memData={memData} />
              <div className="clearfix"></div>
            </div>
          </header>
          <DashSidebar active={toggle} toggle={ToggleActive} />
        </>
      )}
    </>
  );
}

export default Header;
