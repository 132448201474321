import React, { useState } from "react";
import { Link } from "react-router-dom";
import Text from "../../common/Text";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import FormProcessingSpinner from "../../common/FormProcessingSpinner";

import { saveReferralLink } from "../../../states/actions/fetchAmbassadorProgramme";
import { eventDateFormat } from "../../../helpers/helpers";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import { priceFormat, doFirstUpperRestLower } from "../../../helpers/helpers";

const LinkGenrator = ({ content, pages, payouts_history }) => {
  const dispatch = useDispatch();

  const links = useSelector((state) => state.fetchAmbassadorProgramme.links);
  const isFormProcessing = useSelector(
    (state) => state.fetchAmbassadorProgramme.isFormProcessing
  );
  const [editLink, setEditLink] = useState({});

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const firstSubmit = (data) => {
    dispatch(saveReferralLink(data));
  };

  const handleCopySucess = () => {
    toast.success("Link copied", TOAST_SETTINGS);
  };

  const handleEditLink = (index) => {
    setEditLink(links[index]);
    window.scrollTo({ top: 300, behavior: "smooth" });
  };

  return (
    <>
      <div className="lnk_genrator">
        <h4 className="heading">
          <Text string={content.referal_links_hheading} />
        </h4>
        <div className="blk_white">
          <Text string={content.referal_links_description} />
          <br />
          <form action="" method="POST" onSubmit={handleSubmit(firstSubmit)}>
            <input
              type="hidden"
              {...register("link_id")}
              value={editLink && editLink.encoded_id}
            />
            <div className="form_row row">
              <div className="col-sm-6">
                <h5>Name your campaign</h5>
                <input
                  type="text"
                  placeholder="i.e. Twitter (where you are posting this link)"
                  className="input"
                  value={editLink && editLink.compaign_name}
                  {...register("compaign_name", {
                    required: "Compaign Name is required."
                  })}
                />
                <span className="validation-error">
                  {errors.compaign_name?.message}
                </span>
              </div>
              <div className="col-sm-6" style={{ display: "none" }}>
                <h5>Persolanise your link</h5>
                <input
                  type="text"
                  placeholder="i.e. your name (it will appear as ukvisajobs.com/your-name)"
                  className="input"
                />
              </div>
              <div className="col-sm-6">
                <h5>Specific website page</h5>
                <select
                  className="input"
                  value={editLink && editLink.referral_page}
                  {...register("referral_page", {
                    required: "Please select a specific website page."
                  })}
                >
                  <option value="">
                    Select the page where you want your referrals to land
                  </option>
                  {pages &&
                    pages.map((page) => (
                      <option value={page.key}>{page.value}</option>
                    ))}
                </select>
                <span className="validation-error">
                  {errors.referral_page?.message}
                </span>
              </div>
            </div>
            <div className="site_blk">
              <button
                type="submit"
                className="site_btn"
                disabled={isFormProcessing}
              >
                Generate referral link
                <FormProcessingSpinner isFormProcessing={isFormProcessing} />
              </button>
            </div>
          </form>
          <a className="edit_img_lnk" onClick={() => setEditLink(null)}>
            <img src="images/new/edit.svg" alt="" />
          </a>
        </div>
      </div>
      <div className="br"></div>
      <div className="lnk_genrator">
        <h4 className="heading">Generated referral links</h4>
        <div class="payment_history_table">
          <table>
            <thead>
              <tr>
                <th>Campaign Name</th>
                <th>Landing page</th>
                <th>Referral link</th>
                <th>Clicks</th>
                <th>Referrals</th>
                <th>Date created</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {links && links.length > 0 ? (
                links.map((link, index) => (
                  <tr>
                    <td>{link.compaign_name}</td>
                    <td>
                      <Link to="/">{link.landing_page}</Link>
                    </td>
                    <td>
                      <Link to="/">{link.referral_link}</Link>
                    </td>
                    <td>{link.clicks}</td>
                    <td>{link.referrals}</td>
                    <td>{eventDateFormat(link.created_date)}</td>
                    <td>
                      <div className="flex act_tbl">
                        <img src="images/new/file.svg" alt="" />
                        <div className="lnk_act">
                          <CopyToClipboard
                            text={link.referral_link}
                            onCopy={handleCopySucess}
                          >
                            <span className="color">Copy link</span>
                          </CopyToClipboard>
                          {/*<span
                            className="color"
                            onClick={() => handleEditLink(index)}
                          >
                            Edit link
                          </span>*/}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>The links that you have generated will show here.</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="br"></div>
      <div className="lnk_genrator">
        <h4 className="heading">Payouts History</h4>
        <div class="payment_history_table">
          <table>
            <thead>
              <tr>
                <th>SR#</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Requested Date</th>
                <th>Approved Date</th>
              </tr>
            </thead>
            <tbody>
              {payouts_history && payouts_history.length > 0 ? (
                payouts_history.map((payout, index) => (
                  <tr>
                    <td>{++index}</td>
                    <td>£{priceFormat(payout.amount)}</td>
                    <td>{doFirstUpperRestLower(payout.status)}</td>
                    <td>{eventDateFormat(payout.requested_date)}</td>
                    <td>
                      {payout.paid_date === "" || payout.paid_date === null
                        ? "-"
                        : eventDateFormat(payout.paid_date)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>Your payouts history will show here.</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default LinkGenrator;
