import React from "react";
import { NavLink } from "react-router-dom";
import { websiteLink } from "../../helpers/helpers";

function Navigation({ active }) {
  return (
    <>
      <nav className="ease">
        <div id="nav" className={active ? "active" : ""}>
          <ul id="lst">
            <li>
              <NavLink
                to={websiteLink("/")}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to={websiteLink("/open-jobs/1")}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Jobs
              </NavLink>
            </li>
            <li>
              <NavLink
                to={websiteLink("/events")}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Events
              </NavLink>
            </li>
            <li>
              <NavLink
                to={websiteLink("/blogs")}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Resources
              </NavLink>
            </li>

            <li>
              <NavLink
                to={websiteLink("/faq")}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                FAQs
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navigation;
