import {
  FETCH_DASHBOARD,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILED,
  //   FETCH_JOBS_SEARCH,
  //   FETCH_JOBS_SEARCH_SUCCESS,
  //   FETCH_JOBS_SEARCH_FAILED,
  SAVE_JOB_STAT,
  SAVE_JOB_STAT_SUCCESS,
  SAVE_JOB_STAT_FAILED,
  SAVE_APPLIED_JOB_STAT,
  SAVE_APPLIED_JOB_STAT_SUCCESS,
  SAVE_APPLIED_JOB_STAT_FAILED,
  MARK_AS_APPLIED,
  MARK_AS_APPLIED_SUCCESS,
  MARK_AS_APPLIED_FAILED,
  DELETE_JOB,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  isAppliedJobStatUpdating: false,
  isMarkAsAppliedProcessing: false,
  //   isJobSaving: false,
  isJobDeleting: false,
  content: {},
  jobs: [],
  error: false,
  applied_jobs: null,
  saved_jobs: null
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_DASHBOARD:
      return {
        ...state,
        isLoading: true,
        content: {},
        jobs: []
      };
    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
        jobs: payload.jobs,
        applied_jobs: payload.applied_jobs,
        saved_jobs: payload.saved_jobs
      };
    case FETCH_DASHBOARD_FAILED:
      return {
        ...state,
        isLoading: false,
        content: {},
        jobs: [],
        error: payload
      };
    // case FETCH_JOBS_SEARCH:
    //   return {
    //     ...state,
    //     isSearching: true
    //   };
    // case FETCH_JOBS_SEARCH_SUCCESS:
    //   return {
    //     ...state,
    //     isSearching: false,
    //     jobs: payload.jobs
    //   };
    // case FETCH_JOBS_SEARCH_FAILED:
    //   return {
    //     ...state,
    //     isSearching: false,
    //     error: payload
    //   };
    case SAVE_JOB_STAT:
      return {
        ...state,
        isAppliedJobStatUpdating: true
      };
    case SAVE_JOB_STAT_SUCCESS:
      return {
        ...state,
        isAppliedJobStatUpdating: false,
        jobs: payload.jobs
      };
    case SAVE_JOB_STAT_FAILED:
      return {
        ...state,
        isAppliedJobStatUpdating: false,
        error: payload
      };
    case SAVE_APPLIED_JOB_STAT:
      return {
        ...state,
        isAppliedJobStatUpdating: true
      };
    case SAVE_APPLIED_JOB_STAT_SUCCESS:
      // const newJobs = state.applied_jobs.map((job, index) => {
      //   if (job.id === payload.job_id) {
      //     job.job_status = payload.action;
      //   }
      //   return newJobs;
      // });
      return {
        ...state,
        isAppliedJobStatUpdating: false,
        // jobs: newJobs,
        applied_jobs: payload.applied_jobs
      };
    case SAVE_APPLIED_JOB_STAT_FAILED:
      return {
        ...state,
        isAppliedJobStatUpdating: false,
        error: payload
      };
    case MARK_AS_APPLIED:
      return {
        ...state,
        isMarkAsAppliedProcessing: true
      };
    case MARK_AS_APPLIED_SUCCESS:
      return {
        ...state,
        isMarkAsAppliedProcessing: false,
        saved_jobs: payload.saved_jobs,
        applied_jobs: payload.applied_jobs
      };
    case MARK_AS_APPLIED_FAILED:
      return {
        ...state,
        isMarkAsAppliedProcessing: false,
        error: payload
      };
    case DELETE_JOB:
      return {
        ...state,
        isJobDeleting: true
      };
    case DELETE_JOB_SUCCESS:
      return {
        ...state,
        isJobDeleting: false,
        saved_jobs: payload.saved_jobs,
        applied_jobs: payload.applied_jobs
      };
    case DELETE_JOB_FAILED:
      return {
        ...state,
        isJobDeleting: false,
        error: payload
      };
    default:
      return state;
  }
}
