import http from "../../helpers/http";
import httpBlob from "../../helpers/http-blob";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SIGN_UP_CONTENT,
  FETCH_SIGN_UP_CONTENT_SUCCESS,
  FETCH_SIGN_UP_CONTENT_FAILED,
  CREATE_ACCOUNT_MESSAGE,
  CREATE_ACCOUNT_MESSAGE_SUCCESS,
  CREATE_ACCOUNT_MESSAGE_FAILED,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
  BACK_TO_SIGNUP
} from "./actionTypes";

export const fetchSignup = (formData) => (dispatch) => {
  dispatch({
    type: FETCH_SIGN_UP_CONTENT,
    payload: null
  });
  http
    .post("signup", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      dispatch({
        type: FETCH_SIGN_UP_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SIGN_UP_CONTENT_FAILED,
        payload: error
      });
    });
};

export const createAccount = (formData) => (dispatch) => {
  formData = helpers.doObjToFormData(formData);
  dispatch({
    type: CREATE_ACCOUNT_MESSAGE,
    payload: null
  });
  httpBlob
    .post("auth/create-account", formData)
    .then(({ data }) => {
      if (data.status) {
        toast.success(
          "An account have been created successfully. We have sent the verification code to your email.",
          TOAST_SETTINGS
        );
        dispatch({
          type: CREATE_ACCOUNT_MESSAGE_SUCCESS,
          payload: data
        });
      } else {
        if (data.validationErrors) {
          toast.error(
            <Text string={data.validationErrors} parse={true} />,
            TOAST_SETTINGS
          );
          dispatch({
            type: CREATE_ACCOUNT_MESSAGE_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: CREATE_ACCOUNT_MESSAGE_FAILED,
        payload: error
      });
      window.location.reload();
    });
};

export const backToSignup = () => (dispatch) => {
  dispatch({
    type: BACK_TO_SIGNUP,
    payload: null
  });
};
export const verifyEmail = (formData) => (dispatch) => {
  formData = helpers.doObjToFormData(formData);
  dispatch({
    type: VERIFY_EMAIL,
    payload: null
  });
  httpBlob
    .post("auth/verify-email", formData)
    .then(({ data }) => {
      if (data.status) {
        toast.success(
          "The email has been verified successfully. Redirecting…",
          TOAST_SETTINGS
        );
        dispatch({
          type: VERIFY_EMAIL_SUCCESS,
          payload: data
        });
        setTimeout(() => {
          window.location.replace("/dashboard");
        }, 4000);
      } else {
        if (data.validationErrors) {
          toast.error(
            <Text string={data.validationErrors} parse={true} />,
            TOAST_SETTINGS
          );
          dispatch({
            type: VERIFY_EMAIL_FAILED,
            payload: null
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: VERIFY_EMAIL_FAILED,
        payload: error
      });
    });
};
